import React, { useEffect, useState } from "react";
import styles from "./story-card-component.module.scss";
import { CSStoryImageRepository } from "@6cuts/storage/story/image";
import { useHistory } from "react-router-dom";
import { ReadPublishedStoryEntity } from "@6cuts/@dto/read.published_story";

interface HomeStoryCardComponentProps {
  story: ReadPublishedStoryEntity;
}

/*
 * オススメストーリーのカードコンポーネント
 */
export const HomeStoryCardComponent: React.FC<HomeStoryCardComponentProps> = (
  props: HomeStoryCardComponentProps
) => {
  const history = useHistory();

  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string | null>(
    null
  );

  useEffect(() => {
    const url: string = CSStoryImageRepository.getPublicStoryImageUrl({
      storyId: props.story.id,
      imageId: props.story.thumbnailImage,
    });
    setThumbnailImageUrl(url);
  }, [props.story]);

  /**
   * タイトルを改行コードで改行するための処理
   * @param text
   */
  const storyTitle = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line: string, index: number) {
      return (
        <p key={index} className="has-text-white has-text-weight-bold">
          {line}
        </p>
      );
    });
  };

  const onClickStoryHandler = () => {
    console.log("click");
    history.push(`/story/${props.story.id}`);
  };

  return (
    <div className={styles.component} onClick={onClickStoryHandler}>
      {thumbnailImageUrl === null && <div></div>}
      {thumbnailImageUrl !== null && (
        <figure className="image is-square">
          <img src={thumbnailImageUrl}></img>
          <div className="black-mask"></div>
          <div className="story-title has-text-centered">
            {storyTitle(props.story.title)}
          </div>
        </figure>
      )}
      <div className="action">
        <button className="button is-outlined is-rounded">開く</button>
      </div>
    </div>
  );
};

import * as firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { WriteEventEntity, EventType } from "@6cuts/@dto/write.event";

export class CreateReviewEvent {
  static readonly COLLECTION_NAME: string = "write.event";

  /*
   * 星をつけるイベントを生成する
   */
  static createStarEvent(data: {
    storyId: string;
    playId: string;
    star: number;
  }): Promise<void> {
    const now: firebase.firestore.FieldValue = firebase.firestore.FieldValue.serverTimestamp();
    return firebase.firestore()
      .collection(this.COLLECTION_NAME)
      .add({
        type: EventType.story.review.star,
        userId: "",
        data: {
          storyId: data.storyId,
          playId: data.playId,
          star: data.star,
        },
        created: now,
      })
      .then((_) => {
        return;
      });
  }

  /*
   * コメントをつけるイベントを生成する
   */
  static createCommentEvent(data: {
    storyId: string;
    playId: string;
    comment: string;
  }): Promise<void> {
    const now: firebase.firestore.FieldValue = firebase.firestore.FieldValue.serverTimestamp();
    return firebase.firestore()
      .collection(this.COLLECTION_NAME)
      .add({
        type: EventType.story.review.comment,
        userId: "",
        data: {
          storyId: data.storyId,
          playId: data.playId,
          comment: data.comment,
        },
        created: now,
      })
      .then((_) => {
        return;
      });
  }
}

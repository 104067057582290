import * as firebase from "firebase";

const config = () => {
    if (process.env.REACT_APP_FB_ENV === "prod") {
        // 本番環境
        return {
            apiKey: process.env.REACT_APP_FB_PRODUCTION_API_KEY,
            authDomain: process.env.REACT_APP_FB_PRODUCTION_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FB_PRODUCTION_DATABASE_URL,
            projectId: process.env.REACT_APP_FB_PRODUCTION_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FB_PRODUCTION_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FB_PRODUCTION_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FB_PRODUCTION_APP_ID,
        }
    } else {
        // 開発検証環境
        return {
            apiKey: process.env.REACT_APP_FB_DEV_API_KEY,
            authDomain: process.env.REACT_APP_FB_DEV_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FB_DEV_DATABASE_URL,
            projectId: process.env.REACT_APP_FB_DEV_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FB_DEV_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FB_DEV_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FB_DEV_APP_ID,
        }
    }
}

const firebaseConfig = config()

firebase.initializeApp(firebaseConfig);
import React, { useState } from "react";
import "./story-result-component.scss";
import { StoryContainer } from "../../story.container";
import { CreateReviewEvent } from "../../../../service/database/event/review";
import { useHistory } from "react-router";
import { AppContainer } from "containers/app/app.container";

enum PageState {
  Score,
  Review,
  Comment,
  End,
}

/*
 * プレイ結果
 */
export const StoryResultComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  const history = useHistory();
  const [pageState, setPageState] = useState<PageState>(PageState.Score);
  const [comment, setComment] = useState<string>("");
  const appContainer = AppContainer.useContainer();

  if (storyContainer.story === undefined) {
    return null;
  }

  const storyTitle = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line) {
      return <p>{line}</p>;
    });
  };

  const deleteNewLine = (myLen: string) => {
    return myLen.replace(/\r?\n/g, "");
  };

  const onClickShareHandler = () => {
    if (storyContainer.story === undefined) {
      return;
    }
    const navi: any = window.navigator;
    if (navi.share !== undefined) {
      const title: string = deleteNewLine(storyContainer.story.title);
      const url: string = `${window.location.origin}/story/${storyContainer.story.id}/?openExternalBrowser=1`;

      let shareText: string = "";
      if (storyContainer.story.scoreEnable) {
        const scoreName: string = storyContainer.story.scoreName;
        const scoreUnit: string = storyContainer.story.scoreUnit;
        shareText = `【${title}】で${scoreName}${storyContainer.scorePoint}${scoreUnit}獲得！\n\nみんなも遊んでみてね！`;
      } else {
        shareText = `【${title}】をプレイしたよ！\n\nみんなも遊んでみてね！`;
      }

      navi.share({
        title: title,
        text: shareText,
        url: url,
      });
    } else {
      alert(`web share api is not supported in this browser.`);
    }
  };

  const onClickRegistHandler = () => {
    history.push("/sign-up");
  };

  const onClickNextHandler = () => {
    setPageState(PageState.Review);
  };

  const onClickReviewHandler = (star: number) => {
    console.log(star);
    CreateReviewEvent.createStarEvent({
      storyId: storyContainer.story!.id,
      playId: storyContainer.playId,
      star: star,
    });
    setPageState(PageState.Comment);
    storyContainer.setReplayDisabled();
  };

  const onChangeCommentHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComment(e.target.value);
  };

  const onClickSendCommentHandler = (_: any) => {
    CreateReviewEvent.createCommentEvent({
      storyId: storyContainer.story!.id,
      playId: storyContainer.playId,
      comment: comment,
    });
    setPageState(PageState.End);
  };

  const onClickBackToTopHandler = (_: any) => {
    window.location.reload();
  };

  switch (pageState) {
    case PageState.Score:
      if (storyContainer.story.scoreEnable === true) {
        return (
          <div className="result-component">
            <div className="tile is-vertical">
              <div className="box score">
                <p>{storyTitle(`【${storyContainer.story?.title}】`)}</p>
                <p>{storyContainer.story?.scoreName}</p>
                <div className="score">
                  <span className="score-point">
                    {storyContainer.scorePoint}
                  </span>
                  <span className="score-suffix">
                    {storyContainer.story?.scoreUnit}
                  </span>
                  <div className="border-line"></div>
                </div>
                <div className="share">
                  <button
                    className="button is-small"
                    onClick={onClickShareHandler}
                  >
                    シェアする
                  </button>
                </div>
                <div className="next">
                  <button
                    className="button is-small is-text has-text-weight-bold"
                    onClick={onClickNextHandler}
                  >
                    次へ
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="follow">
              <button className="button is-white is-small">
                公式 Twitter をフォローして他の作品情報を得る
              </button>
            </div> */}
          </div>
        );
      } else {
        return (
          <div className="result-component">
            <div className="tile is-vertical">
              <div className="box score">
                <p>{storyTitle(`${storyContainer.story.title}`)}</p>
                <div className="share no-score">
                  <button
                    className="button is-small"
                    onClick={onClickShareHandler}
                  >
                    シェアする
                  </button>
                </div>
                <div className="next">
                  <button
                    className="button is-small is-text has-text-weight-bold"
                    onClick={onClickNextHandler}
                  >
                    次へ
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="follow">
              <button className="button is-white is-small">
                公式 Twitter をフォローして他の作品情報を得る
              </button>
            </div> */}
          </div>
        );
      }

    case PageState.Review:
      return (
        <div className="result-component">
          <div className="tile is-vertical">
            <div className="box review">
              <p>ありがとうございました。</p>
              <p>この作品の評価をお願いします。</p>
              <button
                className="button is-dark"
                onClick={() => onClickReviewHandler(5)}
              >
                <div className="columns is-gapless is-mobile">
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                </div>
              </button>
              <button
                className="button is-dark"
                onClick={() => onClickReviewHandler(4)}
              >
                <div className="columns is-gapless is-mobile">
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                </div>
              </button>
              <button
                className="button is-dark"
                onClick={() => onClickReviewHandler(3)}
              >
                <div className="columns is-gapless is-mobile">
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                </div>
              </button>
              <button
                className="button is-dark"
                onClick={() => onClickReviewHandler(2)}
              >
                <div className="columns is-gapless is-mobile">
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                </div>
              </button>
              <button
                className="button is-dark"
                onClick={() => onClickReviewHandler(1)}
              >
                <div className="columns is-gapless is-mobile">
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                  <div className="column">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`}
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
          {/* <div className="follow">
            <button className="button is-white is-small">
              公式 Twitter をフォローして他の作品情報を得る
            </button>
          </div> */}
        </div>
      );

    case PageState.Comment:
      return (
        <div className="result-component">
          <div className="tile is-vertical">
            <div className="box comment">
              <p>ありがとうございました。</p>
              <p>この作品の評価をお願いします。</p>
              <div className="field">
                <div className="control">
                  <textarea
                    className="textarea has-fixed-size"
                    placeholder="作品へのメッセージがありましたら、よろしくお願い致します。"
                    onChange={onChangeCommentHandler}
                  ></textarea>
                </div>
              </div>

              <div className="next">
                <button
                  className="button is-small is-text has-text-weight-bold"
                  onClick={onClickSendCommentHandler}
                >
                  送る
                </button>
              </div>
            </div>
          </div>
          {appContainer.isAnonymous && (
            <div className="regist">
              <button className="button yes" onClick={onClickRegistHandler}>
                本登録してプレイデータを保存する
              </button>
            </div>
          )}
          {/* <div className="follow">
            <button className="button is-white is-small">
              公式 Twitter をフォローして他の作品情報を得る
            </button>
          </div> */}
        </div>
      );

    case PageState.End:
      return (
        <div className="result-component">
          <div className="tile is-vertical">
            <div className="box end">
              <div className="next">
                <button
                  className="button is-small is-text has-text-weight-bold"
                  onClick={onClickBackToTopHandler}
                >
                  TOPへ戻る
                </button>
              </div>
            </div>
          </div>
          {/* <div className="follow">
            <button className="button is-white is-small">
              公式 Twitter をフォローして他の作品情報を得る
            </button>
          </div> */}
        </div>
      );
  }
};

import * as firebase from "firebase"
import { docData, collectionData } from 'rxfire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RecommendConfigEntity } from "@6cuts/@dto/config";


export class FSConfigRepository {

    static collectionName(): string {
        return `config`
    }

    /**
     * リダイレクトURLを取得する
     * @param data 
     */
    static getRedirectUrlFromAdvertisementId(
        data: {
            advertisementId: string,
        }
    ): Observable<string | null> {
        const docRef = firebase.firestore()
            .collection(this.collectionName())
            .doc("advertisement") as firebase.firestore.DocumentReference<any>

        return docData<any>(docRef)
            .pipe(
                take(1),
                map((entity: any) => {
                    if (entity[data.advertisementId] === undefined) {
                        return null
                    }

                    return `${entity[data.advertisementId]}`
                })
            )
    }

    /**
     * 運営オススメ情報を取得
     * @param data 
     */
    static getRecommendStoryIdList(
    ): Promise<string[]> {
        const docRef = firebase.firestore()
            .collection(this.collectionName())
            .doc("recommend") as firebase.firestore.DocumentReference<RecommendConfigEntity>

        return docData<RecommendConfigEntity>(docRef)
            .pipe(
                take(1),
                map((entity: RecommendConfigEntity) => {
                    if (entity.storyList === undefined) {
                        return []
                    }

                    return entity.storyList
                })
            )
            .toPromise()
    }
}

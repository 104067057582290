import React, { useState, useRef, useEffect } from "react";
import { StoryContainer } from "../../story.container";
import "./story-video.component.scss";

export const StoryVideoComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  //ロード中フラグ
  const [isLoading, setIsLoading] = useState<boolean>(true);
  //ビデオ終了イベント
  const videoEnded = () => {
    storyContainer.setIsVideoEnd(true);
    storyContainer.setIsPlayVideo(false);
  };

  //ロードスタートイベント
  const videoLoadedStat = () => {
    setIsLoading(true);
  };

  //ロード完了イベント
  const videoLoadedData = () => {
    setIsLoading(false);
  };

  //再生ボタン
  const onClickPlayButton = () => {
    if (!storyContainer.isVideoEnd) {
      storyContainer.switchPlayVideo();
    }
  };

  useEffect(() => {
    if (
      storyContainer.videoEl === null ||
      storyContainer.videoEl.current === null
    ) {
      return;
    }
    if (storyContainer.isPlayVideo) {
      storyContainer.videoEl.current.play();
      storyContainer.videoEl.current.muted = false;
    } else {
      storyContainer.videoEl.current.pause();
    }
  }, [storyContainer.isPlayVideo]);

  //スキップボタン
  const onClickSkipButton = () => {
    if (storyContainer.videoEl.current) {
      const duration: number = storyContainer.videoEl.current.duration;
      storyContainer.videoEl.current.currentTime = duration - 0.1;
      storyContainer.setIsVideoEnd(true);
    }
  };

  return (
    <div className="video-wrapper">
      <video
        ref={storyContainer.videoEl}
        className="background-video"
        muted={true}
        autoPlay
        playsInline={true}
        onEnded={videoEnded}
        onLoadStart={videoLoadedStat}
        onCanPlay={videoLoadedData}
        src={`${storyContainer.videoUrl}#t=0.01`}
      ></video>
      {(!storyContainer.isPlayVideo || storyContainer.isVideoEnd) && (
        <div className="black-mask"></div>
      )}
      {!isLoading && (
        <div className="control-wrapper">
          <div className="control-panel" onClick={onClickPlayButton}>
            {!storyContainer.isPlayVideo && !storyContainer.isVideoEnd && (
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/dm-play.png`}
              />
            )}
          </div>
          {!storyContainer.isPlayVideo && !storyContainer.isVideoEnd && (
            <div className="skip-button" onClick={onClickSkipButton}>
              <img src={`${process.env.PUBLIC_URL}/assets/images/right.png`} />
            </div>
          )}
        </div>
      )}
      {isLoading && (
        <div className="loading-panel">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

import * as firebase from "firebase"
import { docData, collectionData } from 'rxfire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ReadOwnUserPruchaseEntity } from "@6cuts/@dto/read.own-user";



export class FSReadOwnUserPurchaseRepository {

    static collectionName(data: { userId: string }): string {
        return `read.own_user/${data.userId}/purchase`
    }

    static getList(
        data: {
            userId: string,
        }
    ): Promise<ReadOwnUserPruchaseEntity[]> {
        return this.watchList({ userId: data.userId })
            .pipe(take(1))
            .toPromise()
    }

    static watchList(
        data: {
            userId: string,
        }
    ): Observable<ReadOwnUserPruchaseEntity[]> {
        const query = firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))
            .where("status", "==", "complete")
            .orderBy("updated", "desc")

        return collectionData<ReadOwnUserPruchaseEntity>(query)
            .pipe(
                map((entityList: ReadOwnUserPruchaseEntity[]) => {
                    if (entityList.length > 0) {
                        return entityList
                    } else {
                        return []
                    }
                })
            )
    }


    /**
     * 対象の決済詳細を取得
     * @param data 
     */
    static get(
        data: {
            userId: string,
            transactionId: string,
        }
    ): Promise<ReadOwnUserPruchaseEntity | null> {
        return this.watch({ userId: data.userId, transactionId: data.transactionId })
            .pipe(take(1))
            .toPromise()
    }

    /**
     * 対象の決済詳細を監視
     * @param data 
     */
    static watch(
        data: {
            userId: string,
            transactionId: string,
        }
    ): Observable<ReadOwnUserPruchaseEntity | null> {
        const docRef = firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))
            .doc(data.transactionId) as firebase.firestore.DocumentReference<ReadOwnUserPruchaseEntity>

        return docData<ReadOwnUserPruchaseEntity>(docRef)
            .pipe(
                map((entity: ReadOwnUserPruchaseEntity) => {
                    if (entity.id === undefined) {
                        return null
                    }

                    return entity
                })
            )
    }

}
import React, { useEffect, useState } from "react";
import { StoryContainer } from "../story/story.container";
import styles from "./page.module.scss";
import { StarComponent } from "../common/star/star-component";
import { useParams, Link } from "react-router-dom";
import { FSReadPublishedStoryRepository } from "@6cuts/public-repository/read.published_story";
import { FSReadPublishedReviewRepository } from "@6cuts/public-repository/read.published_review";
import { ReadPublishedReviewEntity } from "@6cuts/@dto/read.published_review";
import { FSGetStorage } from "service/storage/get-storage";
import { ReadPublishedStoryEntity } from "@6cuts/@dto/read.published_story";
import { useHistory } from "react-router";
import { AppContainer } from "containers/app/app.container";
import { CSStoryImageRepository } from "@6cuts/storage/story/image";
import { LoadingPageComponent } from "components/loading/page";

/*
 * ストーリートップ
 */
export const StoryGatePageComponent: React.FC = () => {
  const { storyId } = useParams();
  const history = useHistory();
  const appContainer = AppContainer.useContainer();

  // TOP画像のダウンロードURL
  const [topImageUrl, setTopImageUrl] = useState<string>();
  const [story, setStory] = useState<ReadPublishedStoryEntity>();
  const [review, setReview] = useState<ReadPublishedReviewEntity>();
  // ストーリーが利用可能か
  const [isStoryAvailable, setIsStoryAvailable] = useState<boolean>(true);
  const [isLoadingHidden, setIsLoadingHidden] = useState<boolean>(false);
  // ローディング
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onClickLogin = () => {
    history.push("/sign-in", {
      storyId: story?.id,
    });
  };

  const onClickGuestLogin = () => {
    setIsSaving(true);
    appContainer.guestSingin().then(() => {
      setIsSaving(false);
      history.push(`/story/${storyId}`);
    });
  };

  useEffect(() => {
    const storySubscription = FSReadPublishedStoryRepository.watchPublishedStory(
      {
        storyId: storyId,
      }
    ).subscribe((storyData: ReadPublishedStoryEntity | null) => {
      if (storyData === null) {
        setIsStoryAvailable(false);
        return;
      }
      setStory(storyData);

      //タイトル画像の取得
      setTopImageUrl(
        CSStoryImageRepository.getPublicStoryImageUrl({
          storyId: storyData.id,
          imageId: storyData.thumbnailImage,
        })
      );
    });

    const reviewSubscription = FSReadPublishedReviewRepository.watchReview({
      storyId: storyId,
    }).subscribe((review: ReadPublishedReviewEntity | null) => {
      console.log(review);
      if (review !== null) {
        setReview(review);
      }
    });

    return () => {
      storySubscription.unsubscribe();
      reviewSubscription.unsubscribe();
    };
  }, []);

  /**
   * タイトルを改行コードで改行するための処理
   * @param text
   */
  const storyTitle = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line: string, index: number) {
      return <p className="title is-3 has-text-white">{line}</p>;
    });
  };

  /**
   * レビュー平均値の算出
   */
  const averageStar = () => {
    if (!review) {
      return 0;
    }
    const averageStar: number =
      review.total_of_stars / review.number_of_reviews;
    return averageStar;
  };

  const onCompletedLoadingHideAnimation = () => {
    setIsLoadingHidden(true);
  };

  if (isStoryAvailable === false) {
    return (
      <div className={styles.no_content}>
        <div className="black-mask">
          <div className="story-not-available">
            <p className="subtitle has-text-white">このコンテンツは</p>
            <p className="subtitle has-text-white">現在利用できません</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <img className="background" src={topImageUrl} alt=""></img>
      <div className="background-mask"></div>
      <div className="black-mask"></div>
      <div className="title-wrapper">
        <StarComponent
          star={averageStar()}
          numberOfReviews={review ? review.number_of_reviews : 0}
        />
        {story?.titleVisible && (
          <div className="story-title">{storyTitle(story?.title)}</div>
        )}
      </div>
      {story !== undefined && (
        <div className="story-top-footer has-text-white">
          <button className="trial button is-light" onClick={onClickLogin}>
            ログイン
          </button>

          <div className="guest">
            <button
              className="button is-text is-small sign-up has-text-white"
              onClick={onClickGuestLogin}
            >
              ゲストアカウントで始める
            </button>
          </div>
          <div className="terms-of-service has-text-centered is-size-7">
            <Link to="/terms/terms-of-service" target="_blank">
              <span className="has-text-white">- 利用規約 -</span>
            </Link>
          </div>
        </div>
      )}
      {!isLoadingHidden && (
        <LoadingPageComponent
          loadingFinished={true}
          onAnimationIteration={() => {}}
          onCompletedHideAnimation={onCompletedLoadingHideAnimation}
        ></LoadingPageComponent>
      )}
      {isSaving && (
        <div className="saving-mask">
          <button className="loading button is-loading is-text"></button>
        </div>
      )}
    </div>
  );
};

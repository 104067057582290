import React, { useState, useEffect, useRef } from "react"
import { useHistory, useParams } from "react-router"

import *  as df from "date-fns"

import styles from "./page.module.scss"

import { AppContainer } from "containers/app/app.container"
import { HeaderComponent } from "components/common/header/component"
import { ReadOwnUserPruchaseEntity } from "@6cuts/@dto/read.own-user"
import { FSReadOwnUserPurchaseRepository } from "@6cuts/public-repository/read.own_user.purchase"


/*
 * 購入詳細画面
 */
export const AccountPurchaseDetailPageComponent: React.FC = () => {
  const { purchaseId } = useParams()
  const appContainer = AppContainer.useContainer()
  const history = useHistory()

  const [purchase, setPurchase] = useState<ReadOwnUserPruchaseEntity | null>(null)
  const purchaseIdEl = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (appContainer.userId === null) {
      return
    }

    FSReadOwnUserPurchaseRepository.get({ userId: appContainer.userId, transactionId: purchaseId })
      .then((entity: ReadOwnUserPruchaseEntity | null) => {
        if (entity === null) {
          return
        }
        setPurchase(entity)
      })
  }, [appContainer.userId])

  /*
    * 決済日時
    */
  const purchaseTime = () => {
    return df.format(purchase!.updated.toDate(), "yyyy-MM-dd HH:mm:ss")
  }

  const onClickPurchaseIdHandler = () => {
    navigator.clipboard.writeText(purchaseId)
  }

  if (purchase === null) {
    return null
  }

  return (
    <div className={styles.page}>
      <HeaderComponent
        backEnabled={true}
        backTo="/account/purchase/history"
        title="決済詳細"
      ></HeaderComponent>

      <div className="item-name">決済ID</div>
      <div className="item-value purchase-id" onClick={onClickPurchaseIdHandler}>
        <span ref={purchaseIdEl}>{purchaseId}</span>
        <i className="far fa-clone"></i>
      </div>

      <div className="item-name">決済日時</div>
      <div className="item-value">{purchaseTime()}</div>

      <div className="item-name">決済金額</div>
      <div className="item-value">{purchase!.price.toLocaleString()} 円</div>

      <div className="item-name">決済方法</div>
      <div className="item-value">{purchase!.paymentType}</div>

      <div className="item-name">対象コンテンツ</div>
      <div className="item-value">{purchase.itemName}</div>
    </div>
  )
}

import React from "react";
import styles from "./component.module.scss";

export interface AmazonPayCompleteProps {
  onClickResumptionHandler: () => void;
}

export const AmazonPayCompleteComponent: React.FC<AmazonPayCompleteProps> = (
  props: AmazonPayCompleteProps
) => {
  return (
    <div className={styles.component}>
      <div className="explanation">
        <p>Amazon Payで</p>
        <p>決済が完了しました。</p>
      </div>
      <div className="action">
        <button
          className="resumption button is-black"
          onClick={props.onClickResumptionHandler}
        >
          <span className="text">プレイを再開</span>
        </button>
      </div>
    </div>
  );
};

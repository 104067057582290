import * as firebase from "firebase";

export class LinkService {
  static linkWithEmailAndPassword(data: {
    email: string;
    password: string;
  }): Promise<firebase.User | null> {
    var credential = firebase.auth.EmailAuthProvider.credential(
      data.email,
      data.password
    );
    const currentUser = firebase.auth().currentUser;
    if (currentUser !== null) {
      return currentUser
        .linkWithCredential(credential)
        .then((usercred) => {
          var user = usercred.user;
          console.log("Anonymous account successfully upgraded", user);
          return user;
        })
        .catch((error) => {
          console.log("Error upgrading anonymous account", error);
          throw error;
        });
    } else {
      return Promise.reject(null);
    }
  }

  static linkTwitter(): Promise<firebase.User | null> {
    return firebase
      .auth()
      .getRedirectResult()
      .then(function (result) {
        const currentUser = firebase.auth().currentUser;
        if (result.credential && currentUser) {
          return currentUser
            .linkWithCredential(result.credential)
            .then((usercred) => {
              var user = usercred.user;
              console.log("Anonymous account successfully upgraded", user);
              return user;
            })
            .catch((error) => {
              console.log("Error upgrading anonymous account", error);
              throw error;
            });
        } else {
          return null;
        }
      });
  }
}

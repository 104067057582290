import React, { useEffect, useState } from "react";
import styles from "./page.module.scss";
import { AppContainer } from "containers/app/app.container";


interface LoadingPageComponentProps {
    loadingFinished: boolean
    onAnimationIteration: () => void
    onCompletedHideAnimation: () => void
}

/*
 * ローディング中
 * ユーザデータ取得中など初回ロード時に利用
 */
export const LoadingPageComponent: React.FC<LoadingPageComponentProps> = (props: LoadingPageComponentProps) => {

    const [isShowLoadingAnimation, setIsShowLoadingAnimation] = useState<boolean>(true);

    const onLogoAnimationIteration = () => {
        if (props.loadingFinished === true) {
            setIsShowLoadingAnimation(false)
        }
    }

    const onBlackMaskAnimationEnd = () => {
        props.onCompletedHideAnimation()
    }

    return (
        <div className={styles.page}>
            <div className="background"></div>
            {
                props.loadingFinished && (
                    <div className="black-mask" onAnimationEnd={onBlackMaskAnimationEnd}></div>
                )
            }
            {
                isShowLoadingAnimation && (
                    <div className="logo">
                        <img className="icon icon-1" src={`${process.env.PUBLIC_URL}/assets/images/icon-parts-1.png`} onAnimationIteration={onLogoAnimationIteration}></img>
                        <img className="icon icon-2" src={`${process.env.PUBLIC_URL}/assets/images/icon-parts-2.png`}></img>
                        <img className="icon icon-3" src={`${process.env.PUBLIC_URL}/assets/images/icon-parts-3.png`}></img>
                        <img className="icon icon-4" src={`${process.env.PUBLIC_URL}/assets/images/icon-parts-4.png`}></img>
                        <img className="icon icon-5" src={`${process.env.PUBLIC_URL}/assets/images/icon-parts-5.png`}></img>
                        <img className="icon icon-6" src={`${process.env.PUBLIC_URL}/assets/images/icon-parts-6.png`}></img>
                    </div>
                )
            }
        </div >
    );
};

import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import *  as df from "date-fns"

import styles from "./purchase-list-cell.module.scss"


import { CSStoryImageRepository } from "@6cuts/storage/story/image"
import { ReadOwnUserPruchaseEntity } from "@6cuts/@dto/read.own-user"
import { FSReadPublishedStoryRepository } from "@6cuts/public-repository/read.published_story"
import { ReadPublishedStoryEntity } from "@6cuts/@dto/read.published_story"
import { ReadPublicUserEntity } from "@6cuts/@dto/read.public-user"
import { FSReadPublicUserRepository } from "@6cuts/public-repository/read.public_user"
import { UserPurchaseItemType } from "@6cuts/@dto/write.user"


interface AccountPurchaseHistoryListCellComponentProps {
  purchase: ReadOwnUserPruchaseEntity
}

/*
 * 決済履歴ページの一覧セルコンポーネント
 */
export const AccountPurchaseHistoryListCellComponent: React.FC<AccountPurchaseHistoryListCellComponentProps> = (props: AccountPurchaseHistoryListCellComponentProps) => {
  const history = useHistory()

  const [story, setStory] = useState<ReadPublishedStoryEntity | null>(null)
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string | null>(null)
  const [storyOwner, setStoryOwner] = useState<ReadPublicUserEntity | null>(null)

  useEffect(() => {
    console.log("purchase")
    console.log(props.purchase)
    if (props.purchase.itemType !== UserPurchaseItemType.story) {
      return
    }

    FSReadPublishedStoryRepository.get({ storyId: props.purchase.itemId })
      .then((entity: ReadPublishedStoryEntity | null) => {
        if (entity === null) {
          return
        }
        setStory(entity)

        // サムネ画像URLの取得
        const url: string = CSStoryImageRepository.getPublicStoryImageUrl({
          storyId: entity.id,
          imageId: entity.thumbnailImage
        })
        setThumbnailImageUrl(url)

        // ストーリーのオーナー情報の取得
        FSReadPublicUserRepository.get({ userId: entity.ownerId })
          .then((ownerEntity: ReadPublicUserEntity | null) => {
            setStoryOwner(ownerEntity)
          })
      })
  }, [props.purchase.itemId])

  /**
   * タイトルを改行コードで改行するための処理
   * @param text
   */
  const lineBreakedStoryTitle = (text: string | undefined) => {
    if (text === undefined) {
      return ""
    }

    const regex = /(\n)/g

    return text.split(regex).map(function (line: string, index: number) {
      return <p key={index} className="title is-4 has-text-weight-bold">{line}</p>
    })
  }

  /**
   * 購入日次
   */
  const purchaseTime = () => {
    return df.format(props.purchase.updated.toDate(), "yyyy-MM-dd HH:mm:ss")
  }

  /**
   * カードをクリックしたときの処理
   * 該当の購入詳細に遷移する
   */
  const onClickCardHandler = () => {
    history.push(`/account/purchase/detail/${props.purchase.transactionId}`)
  }

  if (story === null || storyOwner === null) {
    return null
  }

  return (
    <div className={styles.component} onClick={onClickCardHandler}>
      <div className="card">
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <div className="subtitle is-6 has-text-weight-bold">{purchaseTime()}</div>
              <div className="price subtitle is-6 has-text-weight-bold">{props.purchase.price.toLocaleString()} 円</div>
              <div>{story!.title}</div>
            </div>
            {
              thumbnailImageUrl !== null && (
                <div className="media-right">
                  <figure className="image is-92x92 is-square">
                    <img src={thumbnailImageUrl} alt="Placeholder image" />
                  </figure>
                </div>
              )
            }
          </div>

        </div>
      </div>
    </div>
  )
}

import * as firebase from "firebase";

import { v4 as uuidv4 } from "uuid";
import { ContactType, WriteContactEntity } from "../@dto/wirte.contact";

export class FSWriteContactRepository {
  static create(data: {
    userId: string;
    name: string;
    email: string;
    details: string;
    contactType: ContactType;
  }): Promise<string> {
    const docId: string = uuidv4();
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

    const entity: WriteContactEntity = {
      id: docId,
      userId: data.userId,
      name: data.name,
      email: data.email,
      contactType: data.contactType,
      details: data.details,
      created: now,
      updated: now,
    };

    return firebase
      .firestore()
      .collection("write.contact")
      .doc(docId)
      .set(entity)
      .then(() => {
        return docId;
      });
  }
}

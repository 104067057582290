import { docData } from 'rxfire/firestore';
import { authState } from 'rxfire/auth';
import { map } from 'rxjs/operators';
import * as firebase from "firebase";

import { Observable } from 'rxjs';
import { WriteUserPurchaseEventEntity, UserPurchaseState, UserPurchasePaymentType } from '@6cuts/@dto/write.user';
import { v4 as uuidv4 } from 'uuid'


export class FSWriteUserPurchaseEventRepository {
    static readonly COLLECTION_NAME: string = "write.user";

    static collection(data: { userId: string }): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
        return firebase.firestore().collection("write.user").doc(data.userId).collection("purchase_event")
    }

    /**
     * 注文イベントを作成する
     * @param data 
     */
    static createOrderEvent(
        data: {
            userId: string,
            itemType: string,  // UserPurchaseItemType
            itemId: string,  // Story ID 等
            itemName: string,  // Story Title 等
            orderId: string,
            paymentType: string,  // UserPurchasePaymentType
            price: number,
            data: { [key: string]: any },
        }
    ): Promise<string> {
        const transactionId: string = uuidv4()
        const entity: WriteUserPurchaseEventEntity = {
            userId: data.userId,
            itemType: data.itemType,
            itemId: data.itemId,
            itemName: data.itemName,
            transactionId: transactionId,
            orderId: data.orderId,
            paymentType: data.paymentType,
            price: data.price,
            status: UserPurchaseState.order,
            data: data.data,
            created: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        }
        return this.collection({ userId: data.userId })
            .add(entity)
            .then((doc) => {
                return transactionId
            })
    }

    static createOtherEvent(
        data: {
            userId: string,
            itemType: string,  // UserPurchaseItemType
            itemId: string,  // Story ID 等
            itemName: string,  // Story Title 等
            orderId: string,
            paymentType: string,  // UserPurchasePaymentType
            transactionId: string,
            price: number,
            status: string,
            data: { [key: string]: any },
        }
    ): Promise<string> {
        const entity: WriteUserPurchaseEventEntity = {
            userId: data.userId,
            itemType: data.itemType,
            itemId: data.itemId,
            itemName: data.itemName,
            transactionId: data.transactionId,
            orderId: data.orderId,
            paymentType: data.paymentType,
            price: data.price,
            status: data.status,
            data: data.data,
            created: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        }
        return this.collection({ userId: data.userId })
            .add(entity)
            .then((doc) => {
                return doc.id
            })
    }

}
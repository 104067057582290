import firebase from "firebase";
import { ReadPlayCountEntity } from "@6cuts/@dto/read.play_count";
import { docData } from "rxfire/firestore";
import { map } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";

const isPlayCountEntity = (arg: any) => {
  return arg.id !== undefined;
};

export class FSGetPlayCountRepository {
  static getPlayCount(storyId: string): Promise<ReadPlayCountEntity | null> {
    return firebase
      .firestore()
      .collection("read.play_count")
      .doc(storyId)
      .get()
      .then(
        (
          doc: firebase.firestore.DocumentSnapshot<
            firebase.firestore.DocumentData
          >
        ) => {
          if (doc.exists) {
            const entity = doc.data() as ReadPlayCountEntity;
            entity.id = doc.id;
            return entity;
          } else {
            return null;
          }
        }
      );
  }

  static watchPlayCount(
    storyId: string
  ): Observable<ReadPlayCountEntity | null> {
    const docRef = firebase
      .firestore()
      .collection("read.play_count")
      .doc(storyId) as firebase.firestore.DocumentReference<
      ReadPlayCountEntity
    >;

    return docData<ReadPlayCountEntity>(docRef).pipe(
      map((entity: ReadPlayCountEntity) => {
        if (!isPlayCountEntity(entity)) {
          return null;
        }

        const user: ReadPlayCountEntity = {
          id: entity.id,
          play: entity.play,
          free_play: entity.free_play,
          purchase: entity.purchase,
          created: entity.created,
          updated: entity.updated,
        };
        return user;
      })
    );
  }
}

import { useState, useEffect, useRef } from "react";
import { createContainer } from "unstated-next";
import { v4 as uuid4 } from "uuid";
import { authState } from "rxfire/auth";
import { take } from "rxjs/operators";

import * as firebase from "firebase";
import { AuthService } from "@6cuts/auth/auth-service";
import { ReadOwnUserEntity, Gender } from "@6cuts/@dto/write.user";
import { FSReadOwnUserRepository } from "@6cuts/public-repository/read.own_user";
import { map } from "rxjs/operators";
import { log } from "util";

// import { User, Gender } from "../../dto/user";
// import {
//   FSGetOwnUserService,
//   UserEntity,
// } from "../../firebase/firestore/user/get-own-user";
// import { auth } from "firebase";
// import { AuthService } from "../../firebase/auth/auth-service";

const useAppContainer = (initialState = "") => {
  const [userId, setUserId] = useState<string | null>(null);
  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  const [user, setUser] = useState<ReadOwnUserEntity | null>(null);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);

  /* UserIDを監視してユーザ情報を取得する */
  useEffect(() => {
    console.log("auth ----------------------");
    authState(firebase.auth())
      .pipe(take(1))
      .subscribe((user: firebase.User | null) => {
        console.log("user:");
        console.log(user);
        if (user !== null) {
          // 認証済ならOK
          console.log(user.uid);
          setUserId(user.uid);
          setIsAnonymous(user.isAnonymous);
          setIsAuth(true);
        } else {
          // 認証してなければ、匿名ログインする
          //  setIsAuth(false);
          //  AuthService.signInWithAnonymous().then(
          //    (user: firebase.User | null) => {
          //    console.log(user);
          //      if (user !== null) {
          //      setUserId(user.uid);
          //      setIsAuth(true);
          //    }
          //});
          setIsAuth(false);
        }
      });
  }, []);

  useEffect(() => {
    console.log("userId:" + userId);
    // UserIDがnullだったらUserもnullにして終了
    if (userId === null) {
      setUser(null);
      setIsAuth(null);
      setIsAnonymous(false);
      return;
    }

    // User情報を取得
    const subscription = FSReadOwnUserRepository.watchUser({
      userId: userId,
    }).subscribe((user: ReadOwnUserEntity | null) => {
      // ユーザ未登録の場合は初期データをセット
      if (user === null) {
        const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
        setUser({
          id: userId,
          nickname: "",
          gender: Gender.unknown,
          birthday: "",
          isExistIcon: false,
          created: now,
          updated: now,
        });
      } else {
        setUser(user);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [userId]);

  const userIsExists = (data: { userId: string }) => {
    return FSReadOwnUserRepository.watchUser({
      userId: data.userId,
    })
      .pipe(take(1))
      .toPromise()
      .then((userEntity: ReadOwnUserEntity | null) => {
        if (userEntity === null) {
          return false;
        } else {
          setUser(userEntity);
          return true;
        }
      });
  };

  // ゲストログイン処理
  const guestSingin = () => {
    return AuthService.signInWithAnonymous().then(
      (user: firebase.User | null) => {
        console.log(user);
        if (user !== null) {
          setUserId(user.uid);
          setIsAuth(true);
          setIsAnonymous(user.isAnonymous);
        }
      }
    );
  };

  //サインアウト処理
  const singOut = async () => {
    setIsAuth(false);
    await AuthService.signOut();
  };

  return {
    userId,
    isAuth,
    user,
    isAnonymous,
    setUserId,
    setIsAuth,
    setUser,
    guestSingin,
    setIsAnonymous,
    userIsExists,
    singOut,
  };
};

export const AppContainer = createContainer(useAppContainer);

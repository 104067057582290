import React, { useEffect, useState } from "react";
import styles from "./page.module.scss";
import { take } from "rxjs/operators";

import { useHistory } from "react-router";

import { AppContainer } from "../../containers/app/app.container";

import { AuthService } from "@6cuts/auth/auth-service";
import { LinkService } from "@6cuts/auth/link-service";
import { HeaderComponent } from "components/common/header/component";
import { FSReadOwnUserPlayStatusRepository } from "@6cuts/public-repository/read.own_user.play_status";
import { ReadOwnUserPlayStatusEntity } from "@6cuts/@dto/read.own-user";

/*
 * サインイン
 */
export const ComebackPageComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const history = useHistory();
  const [storyId, setStoryId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    if (appContainer.userId !== null) {
      FSReadOwnUserPlayStatusRepository.watchList({
        userId: appContainer.userId,
      }).subscribe((list: ReadOwnUserPlayStatusEntity[]) => {
        if (list.length > 0) {
          setStoryId(list[0].storyId);
        } else {
          setStoryId("");
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  const onClickYesHandler = () => {
    if (storyId !== "") {
      history.push(`/story/${storyId}`);
    } else {
      history.push("/account");
    }
  };
  const onClickNoHandler = () => {
    history.push("/account");
  };

  return (
    <div className={styles.page}>
      <HeaderComponent
        backEnabled={true}
        backTo="/sign-in"
        title="再開の確認"
        actionButtonDisabled={true}
      ></HeaderComponent>
      <div className="discript">
        <p>プレイ途中のストーリーがあります。</p>
        <p>戻りますか？</p>
      </div>

      <div className="action">
        <div>
          <button className="button yes" onClick={onClickYesHandler}>
            はい
          </button>
        </div>
        <div>
          <button className="button no" onClick={onClickNoHandler}>
            いいえ
          </button>
        </div>
      </div>
      {isLoading && (
        <div className="saving-mask">
          <button className="loading button is-loading is-text"></button>
        </div>
      )}
    </div>
  );
};

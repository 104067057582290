import React, { useEffect } from "react";
import styles from "./page.module.scss";
import { useHistory } from "react-router";

/*
 * プライバシーポリシー
 */
export const PrivacyPolicyPageComponent: React.FC = () => {
  const history = useHistory();

  const onClickBackHandler = () => {
    history.goBack();
  };

  return (
    <div className={styles.page}>
      <div className="section">
        <div className="container">
          <div>
            <p className="title is-size-4">6cutsプライバシーポリシー</p>
          </div>
          <div></div>
          <div>
            <p>第1条（定義）</p>
          </div>
          <div>
            <p>
              6cutsプライバシーポリシー（以下、「本プライバシーポリシー」といいます）は、株式会社プラプラ（以下、「当社」といいます）が6cutsのサービス名で提供するすべてのサービス（以下「本サービス」といいます。）における利用者情報の取扱いを定めたものです。なお、利用者情報の提供をご希望されない場合、本サービスをご利用いただけません。
            </p>
          </div>
          <div></div>
          <div>
            <p>第2条（利用者情報の取得と利用目的）</p>
          </div>
          <div>
            <p>
              当社は、本サービスの提供等にあたり、下記に記載する利用者情報を自動的に取得あるいは利用者自身の入力により取得し、利用目的の達成に必要な範囲で取り扱います。
            </p>
          </div>
          <div></div>
          <div>
            <div>
              <div id="{52c2d168-6fbe-4c4e-aa22-766939e5caf7}{11}"></div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>利用者情報</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>利用目的</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>取得方法</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>端末情報(OS名・機種名・ユーザーエージェント)</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>本サービスの提供および改善のため</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>アプリケーションによる自動取得</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>行動履歴</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>本サービスの提供および改善のため</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>アプリケーションによる自動取得</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>ユーザ登録情報</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>本サービスへのユーザ登録のため</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>利用者による入力操作</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>
                            ログインID・メールアドレス・パスワード・電話番号
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>本サービスの会員認証および問い合わせ対応のため</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>
                            利用者による入力操作もしくはブラウザに保持されているCookie情報からの自動取得
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>Cookie</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>本サービスの提供および改善のため</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>アプリケーションによる自動取得</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div></div>
          <div>
            <p>第3条（同意）</p>
          </div>
          <div>
            <p>
              本サービスは、本プライバシーポリシーおよび本サービス利用規約をご確認いただき、内容をご理解したうえでご利用ください。本プライバシーポリシーは掲示をもって公表したものとします。利用者は本サービスを利用開始する前に、本プライバシーポリシーをご確認ください。
            </p>
          </div>
          <div></div>
          <div>
            <p>第4条（利用者情報の端末外部へのデータ送信および第三者提供等）</p>
          </div>
          <div>
            <p>
              当社は第2条に基づいて取得した利用者情報を、当社の管理するサーバに転送します。また、本サービスの品質向上を目的として、下記の事業者が提供する情報収集モジュールを使用し、当該事業者を通じて本サービス上の行動履歴情報および端末情報を匿名で自動取得します。
            </p>
          </div>
          <div></div>
          <div>
            <p>事業者：Google Inc.</p>
          </div>
          <div>
            <p>情報収集モジュール名：Firebase Analytics</p>
          </div>
          <div></div>
          <div>
            <p>
              お客様は、当社が本条の定めにより収集するお客様に関する情報の一部を、Googleが提供する情報収集ツール（以下本条において「Firebase向けGoogleアナリティクス」といいます）の利用のため、同社に提供することを承諾するものとします。Firebase向けGoogleアナリティクスは、当社から取得した情報を元に、モバイル端末向けID（Android向け広告ID、iOS向け広告IDなど）を使用してお客様が本サービスにおいて行ったアクション(初回起動、画面遷移、アクティブ状況など)及びお客様の属性（年齢、性別、国、言語、アプリストア、アプリケーションのバージョン、OSバージョン、デバイスモデル、デバイスメーカー、デバイスカテゴリ、インタレストカテゴリ）に関する情報を集計し当社に提供します。当社は、Firebase向けGoogleアナリティクスに対し、前記分析に必要な限度でのみ情報を提供し、お客様のコンテンツ閲覧履歴を提供することはありません。また、Googleは、Firebase向けGoogleアナリティクスによって収集された情報は同社のプライバシーポリシーに従って取り扱い、第三者に共有しないことを表明しております。さらに、Firebase向けGoogleアナリティクスの規約上、Firebase向けGoogleアナリティクスにより作成されたデータに個人を特定する情報は含まれません。Googleによる情報の収集および利用については、同社のプライバシーポリシーによって定められており、以下のＵＲＬから確認できます。
            </p>
          </div>
          <div></div>
          <div>
            <p>Googleのプライバシーポリシー</p>
          </div>
          <div>
            <p>
              <a
                href="https://www.google.com/intl/ja/policies/privacy/"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://www.google.com/intl/ja/policies/privacy/
              </a>
            </p>
          </div>
          <div>
            <p>Android搭載デバイスでのオプトアウト</p>
          </div>
          <div>
            <p>
              <a
                href="https://support.google.com/googleplay/answer/3405269"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://support.google.com/googleplay/answer/3405269
              </a>
            </p>
          </div>
          <div>
            <p>iOS搭載デバイスでのオプトアウト</p>
          </div>
          <div>
            <p>
              <a
                href="https://support.apple.com/ja-jp/HT202074"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://support.apple.com/ja-jp/HT202074
              </a>
            </p>
          </div>
          <div></div>
          <div>
            <p>第5条（外部サービスとの連携）</p>
          </div>
          <div>
            <p>
              twitterなどの外部サービス（以下「外部サービス」といいます。）と連携して本サービスを提供する場合、利用者は当社が外部サービスから取得した会員の情報（外部サービスにおける公開、非公開等の設定により、利用者及びその友達のプロフィール画像、職歴、学歴、職業等が含まれる場合があります）を当サイトで他の利用者、掲載企業が閲覧等することを予め了承の上、本サービスを利用するものとします。
            </p>
          </div>
          <div></div>
          <div>
            <p>第6条（利用目的の変更）</p>
          </div>
          <div>
            <p>
              1．当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
            </p>
          </div>
          <div>
            <p>
              2．利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
            </p>
          </div>
          <div></div>
          <div>
            <p>第7条（個人情報の第三者提供）</p>
          </div>
          <div>
            <p>
              1．当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
            </p>
          </div>
          <div>
            <p>
              ⅰ．人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
            </p>
          </div>
          <div>
            <p>
              ⅱ．公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
            </p>
          </div>
          <div>
            <p>
              ⅲ．国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </p>
          </div>
          <div>
            <p>
              ⅳ．予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき
            </p>
          </div>
          <div>
            <p>・利用目的に第三者への提供を含むこと</p>
          </div>
          <div>
            <p>・第三者に提供されるデータの項目</p>
          </div>
          <div>
            <p>・第三者への提供の手段または方法</p>
          </div>
          <div>
            <p>・本人の求めに応じて個人情報の第三者への提供を停止すること</p>
          </div>
          <div>
            <p>・本人の求めを受け付ける方法</p>
          </div>
          <div>
            <p>
              2．前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
            </p>
          </div>
          <div>
            <p>
              ⅰ．当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
            </p>
          </div>
          <div>
            <p>
              ⅱ．合併その他の事由による事業の承継に伴って個人情報が提供される場合
            </p>
          </div>
          <div>
            <p>
              ⅲ．個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合
            </p>
          </div>
          <div></div>
          <div>
            <p>第8条（個人情報の開示）</p>
          </div>
          <div>
            <p>
              1．当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。
            </p>
          </div>
          <div>
            <p>
              ⅰ．本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
            </p>
          </div>
          <div>
            <p>ⅱ．当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</p>
          </div>
          <div>
            <p>ⅲ．その他法令に違反することとなる場合</p>
          </div>
          <div>
            <p>
              2．前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
            </p>
          </div>
          <div></div>
          <div>
            <p>第9条（個人情報の訂正および削除）</p>
          </div>
          <div>
            <p>
              1．ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
            </p>
          </div>
          <div>
            <p>
              2．当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
            </p>
          </div>
          <div>
            <p>
              3．当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
            </p>
          </div>
          <div></div>
          <div>
            <p>第10条（個人情報の利用停止等）</p>
          </div>
          <div>
            <p>
              1．当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
            </p>
          </div>
          <div>
            <p>
              2．前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
            </p>
          </div>
          <div>
            <p>
              3．当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
            </p>
          </div>
          <div>
            <p>
              4．前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
            </p>
          </div>
          <div></div>
          <div>
            <p>第11条（プライバシーポリシーの変更）</p>
          </div>
          <div>
            <p>
              1．本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
            </p>
          </div>
          <div>
            <p>
              2．当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
            </p>
          </div>
          <div></div>
          <div>
            <p>第12条（お問い合わせ窓口）</p>
          </div>
          <div>
            <p>
              本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
            </p>
          </div>
          <div>
            <p>support@plpl.jp</p>
          </div>
          <div></div>
          <div>
            <p>第13条（変更）</p>
          </div>
          <div>
            <p>
              当社は、法令の変更等に伴い、本アプリケーションプライバシーポリシーを変更することがあります。
            </p>
          </div>
          <div></div>
          <div>
            <p>
              当社は、本アプリケーションのバージョンアップに伴って、利用者情報の取得項目の変更や追加、利用目的の変更、第三者提供等について変更がある場合には、ダウンロード前に通知し、重要なものについてはインストール前もしくはインストール時にあらためて同意を取得いたします。
            </p>
          </div>
          <div></div>
          <div>
            <p>（附則）</p>
          </div>
          <div>
            <p>2020年07月01日制定・施行</p>
          </div>
        </div>
      </div>
    </div>
  );
};

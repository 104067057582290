import * as firebase from "firebase"
import { docData } from 'rxfire/firestore';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ReadPublicUserEntity } from "@6cuts/@dto/read.public-user";


const isUserEntity = (arg: any) => {
    return arg.id !== undefined
}

export class FSReadPublicUserRepository {
    static readonly COLLECTION_NAME: string = "read.public_user";

    static get(data: { userId: string }): Promise<ReadPublicUserEntity | null> {
        return this.watchUser({ userId: data.userId })
            .pipe(take(1))
            .toPromise()
    }

    static watchUser(data: { userId: string }): Observable<ReadPublicUserEntity | null> {
        const docRef = firebase.firestore()
            .collection(this.COLLECTION_NAME)
            .doc(data.userId) as firebase.firestore.DocumentReference<ReadPublicUserEntity>

        return docData<ReadPublicUserEntity>(docRef)
            .pipe(
                map((entity: ReadPublicUserEntity) => {
                    if (!isUserEntity(entity)) {
                        return null
                    }

                    const user: ReadPublicUserEntity = {
                        id: entity.id,
                        nickname: entity.nickname,
                        isExistIcon: entity.isExistIcon,
                    }
                    return user
                })
            )
    }
}

export interface WriteContactEntity {
  id: string;
  userId: string;
  name: string;
  email: string;
  contactType: ContactType;
  details: string;
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
}

export enum ContactType {
  Request = "request", //要望
  Trouble = "trouble", //不具合
  Parchase = "parchase", //支払い
  Other = "other", //その他
}

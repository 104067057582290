import React, { useState } from "react";
import "./story-select.component.scss";
import { StoryContainer } from "../../story.container";
import {
  ChapterActionSelect,
  ChapterActionType,
} from "@6cuts/@dto/write.story";
import { UserPlayAction } from "@6cuts/@dto/write.user";
import { AppContainer } from "containers/app/app.container";

interface StorySelectProps {
  selectList: ChapterActionSelect[];
}

export const StorySelectComponent: React.FC<StorySelectProps> = (
  props: StorySelectProps
) => {
  const storyContainer = StoryContainer.useContainer();
  const appContainer = AppContainer.useContainer();

  const onClickScreen = () => {
    storyContainer.switchFullScreen();
  };

  const onClickSelectButton = async (
    select: ChapterActionSelect,
    actionIndex: number
  ) => {
    if (!storyContainer.currentChapter) {
      return;
    }

    const action: UserPlayAction = {
      chapterId: storyContainer.currentChapter.chapterId,
      type: ChapterActionType.SELECT,
      action: actionIndex,
      nextChapterId: select.transitionId,
    };

    if (storyContainer.isMoveNextStoryEnabled()) {
      storyContainer.saveUserPlayAction(action, select.point);
      storyContainer.addScorePoint(select.point);
      storyContainer.moveToNextChapter(select.transitionId);
      storyContainer.playScorePoint(select.point);
    } else {
      //アクションを一時保存
      storyContainer.setNextChapterActionInfo({
        nextChapterId: select.transitionId,
        point: select.point,
        action: action,
      });
      //フリープレイ可能かどうか判断する
      if (storyContainer.isFreePlay) {
        storyContainer.setIsShowFreePlayModal(true);
      } else {
        if (appContainer.isAnonymous) {
          storyContainer.setIsShowLoginModal(true);
        } else {
          // 課金画面表示
          storyContainer.setIsShowPurchaseModal(true);
        }
      }
    }
  };

  return (
    <div className="select-wrapper">
      <div className="fullscreen-panel" onClick={onClickScreen}></div>
      {!storyContainer.isFullScreen && (
        <div className="select-list">
          {props.selectList.map((select, index) => (
            <button
              key={index}
              disabled={storyContainer.scorePoint < select.selectPointLimit}
              className="select-button button is-light"
              onClick={() => onClickSelectButton(select, index)}
            >
              {select.text}
              {select.selectPointLimit != 0 && (
                <div className="limit-point-text">
                  {storyContainer.story?.scoreName}
                  {select.selectPointLimit}以上
                </div>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import styles from "./star-component.module.scss";

interface StarComponentProps {
  star: number;
  numberOfReviews: number;
}

export const StarComponent: React.FC<StarComponentProps> = (
  props: StarComponentProps
) => {
  if (props.star >= 5) {
    return (
      <div className={styles.reviewRowComponent}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <span className="number-of-reviews">({props.numberOfReviews})</span>
      </div>
    );
  } else if (props.star >= 4) {
    return (
      <div className={styles.reviewRowComponent}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <span className="number-of-reviews">({props.numberOfReviews})</span>
      </div>
    );
  } else if (props.star >= 3) {
    return (
      <div className={styles.reviewRowComponent}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <span className="number-of-reviews">({props.numberOfReviews})</span>
      </div>
    );
  } else if (props.star >= 2) {
    return (
      <div className={styles.reviewRowComponent}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <span className="number-of-reviews">({props.numberOfReviews})</span>
      </div>
    );
  } else if (props.star >= 1) {
    return (
      <div className={styles.reviewRowComponent}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_1.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <span className="number-of-reviews">({props.numberOfReviews})</span>
      </div>
    );
  } else {
    return (
      <div className={styles.reviewRowComponent}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <img src={`${process.env.PUBLIC_URL}/assets/images/star_0.png`} />
        <span className="number-of-reviews">({props.numberOfReviews})</span>
      </div>
    );
  }
};

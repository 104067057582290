import React, { useState, useRef, useEffect } from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router";
import * as firebase from "firebase";

import { AppContainer } from "./containers/app/app.container";
import { useRouteMatch } from "react-router-dom";

// /* ログイン状態専用のルーティング */
// export const PrivateRoute: React.FC<RouteProps> = (props) => {
//   const appContainer = AppContainer.useContainer();
//   const location = useLocation();
//   console.log("private route");
//   if (appContainer.isAuth === false || appContainer.user === null) {
//     return <Redirect to="/sign-in" />;
//   } else if (
//     appContainer.user.nickname === "" &&
//     location.pathname !== "/account/edit"
//   ) {
//     return <Redirect to="/account/edit" />;
//   } else {
//     return <Route {...props} />;
//   }
// };

// /* 未ログイン状態専用のルーティング */
// export const GuestRoute: React.FC<RouteProps> = (props) => {
//   console.log(props.path);
//   const appContainer = AppContainer.useContainer();
//   console.log("guest route");
//   console.log(props);

//   return !appContainer.isAuth ? (
//     <Route {...props} />
//   ) : (
//     <Redirect to="/sign-in" />
//   );
// };

/**
 * アカウント連携済みの専用ルーティング
 * （本ログインのみ）
 * @param props
 */
export const LinkedRoute: React.FC<RouteProps> = (props) => {
  const appContainer = AppContainer.useContainer();
  console.log("private route");
  if (
    appContainer.isAuth === false ||
    appContainer.isAnonymous ||
    appContainer.user === null
  ) {
    if (props.path === "/") {
      return <Redirect to="/portal" />;
    } else {
      return <Redirect to="/sign-in" />;
    }
  } else if (
    appContainer.user.nickname === "" &&
    props.path !== "/account/edit"
  ) {
    return <Redirect to="/account/edit" />;
  } else {
    return <Route {...props} />;
  }
};

/**
 * ログイン済みの専用ルーティング
 * （本ログイン、ゲストログインのみ）
 * @param props
 */
export const LoggedInRoute: React.FC<RouteProps> = (props) => {
  const appContainer = AppContainer.useContainer();
  console.log("logged in route");

  if (appContainer.isAuth && appContainer.user !== null) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/sign-in" />;
  }
};

/**
 * アカウント連携していない専用ルーティング
 * (未ログイン、ゲストログインのみ)
 * @param props
 */
export const UnlinkedRoute: React.FC<RouteProps> = (props) => {
  const appContainer = AppContainer.useContainer();
  console.log("unlinked route");

  if (!appContainer.isAnonymous && appContainer.isAuth) {
    return <Redirect to="/account" />;
  } else {
    return <Route {...props} />;
  }
};

import { useHistory } from "react-router-dom";
import styles from "./component.module.scss";
import React, { useEffect, useState } from "react";
import { AppContainer } from "containers/app/app.container";
import { CSUserImageRepository } from "@6cuts/storage/users/image";

export interface HeaderComponentProps {
  backEnabled: boolean
  backTo?: string
  title?: string
  actionButtonDisabled?: boolean
}

export const HeaderComponent: React.FC<HeaderComponentProps> = (
  props: HeaderComponentProps
) => {
  const history = useHistory()
  const appContainer = AppContainer.useContainer()

  const [userImageUrl, setUserImageUrl] = useState<string | null>(null)

  useEffect(() => {
    if (appContainer.userId === null) {
      return
    }
    CSUserImageRepository.getUserThumbnailImageUrl({
      userId: appContainer.userId
    }).then((url: string) => {
      const now = new Date().getTime()
      setUserImageUrl(`${url}&time=${now}`)
    })
  }, [appContainer.user?.updated])

  const onClickBackButtonHandler = () => {
    if (props.backEnabled === false) {
      return
    }

    if (props.backTo === undefined) {
      history.push("/");
    } else {
      history.push(props.backTo);
    }
  }

  const onClickSignInHandler = () => {
    history.push("/sign-in")
  }

  const onClickUserHandler = () => {
    history.push("/account")
  }

  return (
    <nav
      className={styles.component}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="center">
        <span className="has-text-black has-text-weight-bold">
          {props.title}
        </span>
      </div>
      <div className="left">
        <a onClick={onClickBackButtonHandler}>
          <figure className="image is-32x32 is-1by1">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/6cuts-icon.png`}
            />
          </figure>
        </a>
      </div>
      {
        props.actionButtonDisabled === undefined && (
          <div className="right">
            {
              appContainer.isAuth === true && userImageUrl !== null && (
                <figure className="account image is-32x32 is-1by1" onClick={onClickUserHandler}>
                  <img
                    className="is-rounded"
                    src={userImageUrl}
                  />
                </figure>
              )
            }
            {
              appContainer.isAuth === false && (
                <button
                  className={`button is-primary is-small`}
                  onClick={onClickSignInHandler}
                >ログイン</button>
              )
            }
          </div>
        )
      }
    </nav>
  )
}

import React, { useEffect, useState } from "react";
import styles from "./page.module.scss";

import { useHistory } from "react-router";
import { AppContainer } from "../../../../../containers/app/app.container";
import { useParams } from "react-router-dom";
import { FSWriteUserPurchaseEventRepository } from "@6cuts/public-repository/write.user.purchase_event";
import { UserPurchaseState } from "@6cuts/@dto/write.user";
import { ReadPublishedStoryEntity } from "@6cuts/@dto/read.published_story";
import { FSReadOwnUserPurchaseRepository } from "@6cuts/public-repository/read.own_user.purchase";
import { ReadOwnUserPruchaseEntity } from "@6cuts/@dto/read.own-user";
import { OkAlertComponent } from "components/common/alert/alert";
import { interval } from "rxjs";

declare const window: any;

/*
 * Amazon購入確定ページ
 */
export const PayWithAmazonPageComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const history = useHistory();
  const { transactionId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [userPruchase, setUserPruchase] = useState<ReadOwnUserPruchaseEntity>();
  const [isPruchase, setIsPruchase] = useState<boolean>(false);

  useEffect(() => {
    window["showWalletWidget"]();
    const purchaseSubscription = FSReadOwnUserPurchaseRepository.watch({
      userId: appContainer.userId!,
      transactionId: transactionId,
    }).subscribe((entity: ReadOwnUserPruchaseEntity | null) => {
      if (entity !== null) {
        setUserPruchase(entity);
      }
    });
    return () => {
      purchaseSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isPruchase) {
      return;
    }
    const subscription = FSReadOwnUserPurchaseRepository.watch({
      userId: appContainer.userId!,
      transactionId: transactionId!,
    }).subscribe((entity: ReadOwnUserPruchaseEntity | null) => {
      if (entity !== null) {
        console.log(entity.status);
        if (entity.status === UserPurchaseState.complete) {
          //支払い完了
          subscription.unsubscribe();
          //読み込みデータに反映されるまで少し待ってから遷移
          const source = interval(1500);
          const subscribe = source.subscribe((val) => {
            setIsLoading(false);
            subscribe.unsubscribe();
            history.push(`/story/${userPruchase?.itemId}`);
          });
        } else if (entity.status === UserPurchaseState.error) {
          setIsLoading(false);
          subscription.unsubscribe();
          //エラー
          setErrorMessage("購入に失敗しました\n最初からやり直してください");
          setIsErrorAlert(true);
        }
      }
    });
  }, [isPruchase]);

  const onClickPurchaseHandler = async () => {
    if (window.amazonPayError !== "") {
      setErrorMessage("購入にできませんでした\n最初からやり直してください");
      return;
    }

    if (userPruchase) {
      console.log(appContainer.userId!);
      setIsLoading(true);
      // オーダーデータを作成
      FSWriteUserPurchaseEventRepository.createOtherEvent({
        userId: appContainer.userId!,
        itemType: userPruchase.itemType,
        itemId: userPruchase.itemId,
        itemName: userPruchase.itemName,
        transactionId: userPruchase.transactionId,
        orderId: window.orderReferenceId,
        paymentType: userPruchase.paymentType,
        price: userPruchase.price,
        status: UserPurchaseState.order,
        data: userPruchase.data,
      })
        .then((id: string) => {
          setIsPruchase(true);
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
    }
  };

  const onClickBackHandler = () => {
    history.goBack();
  };

  return (
    <div className={styles.page}>
      <div className="title is-size-4">Amazonアカウントでお支払い</div>
      <div id="walletWidgetDiv" className="amazonPayWidget"></div>
      {userPruchase && (
        <div>
          <div className="price title is-size-3">{userPruchase.price}円</div>
          <div className="action">
            <div>
              <button
                className="purchase button is-black"
                onClick={onClickPurchaseHandler}
              >
                <span className="text">確定</span>
              </button>
            </div>
            <div>
              <button
                className="button is-text is-small cancel"
                onClick={onClickBackHandler}
              >
                支払いをキャンセルして戻る
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="saving-mask">
          <button className="loading button is-loading is-text"></button>
        </div>
      )}
      <OkAlertComponent
        title="エラー"
        message={errorMessage}
        okCallback={() => {
          history.goBack();
        }}
        isOpen={isErrorAlert}
      />
    </div>
  );
};

import React, { useEffect } from "react";
import styles from "./page.module.scss";
import { take } from "rxjs/operators";

import { useHistory } from "react-router";

import { AppContainer } from "../../../containers/app/app.container";

import { AuthService } from "@6cuts/auth/auth-service";
import { ReadOwnUserEntity } from "@6cuts/@dto/read.own-user";
import { useLocation } from "react-router-dom";
import { HeaderComponent } from "components/common/header/component";
import { LinkService } from "@6cuts/auth/link-service";

/*
 * サインイン
 */
export const SignInTopPageComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const history = useHistory();
  const state = useLocation().state as any;
  const storyId = state ? state.storyId : "";
  console.log(storyId);

  useEffect(() => {
    if (appContainer.isAuth && appContainer.isAnonymous) {
      //ツイッター連携
      LinkService.linkTwitter().then((user: firebase.User | null) => {
        if (user === null) {
          return;
        }
        login(user);
      });
    } else {
      AuthService.checkRedirectResult().then((user: firebase.User | null) => {
        if (user === null) {
          return;
        }
        login(user);
      });
    }
  });

  const login = async (user: firebase.User) => {
    appContainer.setIsAuth(true);
    appContainer.setUserId(user.uid);
    appContainer.setIsAnonymous(user.isAnonymous);
    const result = await appContainer.userIsExists({ userId: user.uid });
    if (result) {
      //ストーリーが存在してればストーリーに移行
      //なければトップへ
      if (storyId !== "") {
        history.push(`/story/${storyId}`);
      } else {
        history.push("/");
      }
    } else {
      // アカウントが存在しなければ作成画面へ
      history.push("/account/edit");
    }
  };

  const onClickTwitterSignInHandler = () => {
    AuthService.signInWithTwitter()
      .then((_) => {})
      .catch((error) => {
        window.alert(error.message);
      });
  };

  const onClickEmailSignInHandler = () => {
    history.push("/sign-in/email", { storyId: storyId });
  };

  return (
    <div className={styles.page}>
      <HeaderComponent
        backEnabled={true}
        title="ログイン"
        actionButtonDisabled={true}
      ></HeaderComponent>
      <div className="sign-in">
        <div className="social" onClick={onClickTwitterSignInHandler}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/twitter_icon.svg`}
            alt=""
          ></img>
          Twitterでログイン
        </div>
        <div className="email" onClick={onClickEmailSignInHandler}>
          <i className="far fa-envelope fa-2x"></i>
          メールアドレスでログイン
        </div>
      </div>
    </div>
  );
};

import React, { useState, ReactEventHandler } from "react";
import styles from "./page.module.scss";
import { AuthService } from "@6cuts/auth/auth-service";
import { useHistory } from "react-router-dom";
import { HeaderComponent } from "components/common/header/component";
import { AppContainer } from "containers/app/app.container";
import { LinkService } from "@6cuts/auth/link-service";

/*
 * サインイン
 */
export const SignUpEmailPageComponent: React.FC = () => {
  const history = useHistory();
  const appContainer = AppContainer.useContainer();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSentEmail, setIsSentEmail] = useState<boolean>(false);

  const onChangeEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onChangePasswordHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(event.target.value);

    if (event.target.value.length < 8) {
      setErrorMessage("パスワードは英数字8文字以上で入力してください。");
    } else {
      setErrorMessage("");
    }
  };

  const onChangePasswordConfirmHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirm(event.target.value);

    if (event.target.value !== password) {
      setErrorMessage("パスワード(確認)が一致しません");
    } else {
      if (password.length < 8) {
        setErrorMessage("パスワードは英数字8文字以上で入力してください。");
      } else {
        setErrorMessage("");
      }
    }
  };

  const onClickSignUpHandler = async () => {
    setIsSaving(true);
    if (appContainer.isAuth && appContainer.isAnonymous) {
      LinkService.linkWithEmailAndPassword({
        email: email,
        password: password,
      })
        .then((user: firebase.User | null) => {
          if (user !== null) {
            user.sendEmailVerification({
              url: `${window.location.origin}/account/edit`,
            });
          }
          setIsSentEmail(true);
          setIsSaving(false);
          //appContainer.setIsAnonymous(false);
        })
        .catch((error) => {
          emailErrorMessage(error);
          setIsSaving(false);
        });
    } else {
      AuthService.signUpWithEmailAndPassword({
        email: email,
        password: password,
      })
        .then((user: firebase.User) => {
          user.sendEmailVerification({
            url: `${window.location.origin}/account/edit`,
          });
          setIsSentEmail(true);
          setIsSaving(false);
        })
        .catch((error) => {
          emailErrorMessage(error);
          setIsSaving(false);
        });
    }
  };

  const emailErrorMessage = (error: any) => {
    const message: string = error.message
      ? error.message
      : "アカウントの作成に失敗しました。";
    if (error.code) {
      switch (error.code) {
        case "auth/invalid-email":
          setErrorMessage("正しいメールアドレスを入力してください。");
          break;
        case "auth/email-already-in-use":
          setErrorMessage("このメールアドレスは既に登録されています。");
          break;
        default:
          setErrorMessage(message);
          break;
      }
    } else {
      setErrorMessage(message);
    }
  };

  if (isSentEmail) {
    return (
      <div className={styles.page}>
        <HeaderComponent
          backEnabled={true}
          title="サインアップ"
        ></HeaderComponent>

        <section className="section">
          <div className="container">
            <div>登録したメールアドレスにメールを送信しました。</div>
            <div>メール内のURLを開いて確認を行ってください。</div>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <HeaderComponent
        backEnabled={true}
        title="サインアップ"
      ></HeaderComponent>

      <section className="section">
        <div className="container">
          <div className="field is-vertical">
            <div className="field-label is-normal">
              <label className="label">メールアドレス</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="email"
                    placeholder="Email"
                    onChange={onChangeEmailHandler}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="field is-vertical">
            <div className="field-label is-normal">
              <label className="label">パスワード</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="password"
                    placeholder="Password"
                    onChange={onChangePasswordHandler}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="field is-vertical">
            <div className="field-label is-normal">
              <label className="label">パスワード (確認)</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="password"
                    placeholder="Password"
                    onChange={onChangePasswordConfirmHandler}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="error-message has-text-danger">{errorMessage}</div>
          <div className="action">
            <div>
              <button
                className="button is-outlined sign-in"
                disabled={password.length < 8 || password !== passwordConfirm}
                onClick={onClickSignUpHandler}
              >
                送信
              </button>
            </div>
          </div>
        </div>
      </section>

      {isSaving && (
        <div className="saving-mask">
          <button className="loading button is-loading is-text"></button>
        </div>
      )}
    </div>
  );
};

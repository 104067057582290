import React, { useEffect } from "react";
import styles from "./page.module.scss";
import { useHistory } from "react-router";

/*
 * 特定商取引法
 */
export const SpecificCommercePageComponent: React.FC = () => {
  const history = useHistory();

  const onClickBackHandler = () => {
    history.goBack();
  };
  return (
    <div className={styles.page}>
      <div className="section">
        <div className="container">
          <div>
            <div>
              <p className="title is-size-4">特定商取引法に基づく表記</p>
            </div>
            <div></div>
            <div>
              <div>
                <div id="{56815b61-9ebd-4009-bab1-c10414869f42}{10}"></div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>サービス名</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>6cuts</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>販売事業者名</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>株式会社プラプラ</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>代表者</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>今井 隆造</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>連絡先／ホームページ</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>https://plpl.jp/</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>連絡先／電子メール</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>support@plpl.jp</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>連絡先／TEL</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>上記メール宛にてご連絡をお願いします。</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>販売価格</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>
                              対象となるデジタルコンテンツ（以下「コンテンツ」）ごとに表示される金額
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>商品等の引き渡し時期（日数）・発送方法</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>
                              当社所定の手続き終了後、直ちにご利用頂けます。
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>代金の支払時期および方法</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>＜代金の支払い時期＞</p>
                          </div>
                          <div>
                            <p>
                              コンテンツ提供の前。
                              <br />
                              <br />
                              ＜支払い方法＞
                              <br />
                              ・paypalにてお支払い頂きます。
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>商品代金以外に必要な費用 ／送料、消費税等</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>
                              サイトの閲覧、コンテンツのダウンロード、お問い合わせ等の際の電子メールの送受信時などに、所定の通信料が発生いたします。
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>
                              返品の取扱条件／返品期限、返品時の送料負担
                              <br />
                              または解約や退会条件
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>
                              電子商品としての性質上、返品・キャンセルには応じられません。
                              <br />
                              <br />
                              1.クーリングオフについて
                              <br />
                              特定商取引法に規定されるクーリング・オフが適用されるサービスではありません。
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <p>不良品の取扱条件</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <p>
                              当社指定の動作環境条件下における動作不良の場合はメールにてお問い合わせ下さい
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div></div>
            <div>
              <p>
                ※上記以外の事項に関しましては、お取引の際に請求があれば遅延なく提示いたします。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

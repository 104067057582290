import { useState, useEffect, useRef } from "react";
import { createContainer } from "unstated-next";

import * as firebase from "firebase";

import { v4 as uuid4 } from "uuid";
import { FSGetStorage } from "../../service/storage/get-storage";
import { FSReadPublishedStoryRepository } from "@6cuts/public-repository/read.published_story";
import { FSReadPublishedReviewRepository } from "@6cuts/public-repository/read.published_review";

import {
  ReadPublishedStoryEntity,
  ReadPublishedChapterEntity,
} from "@6cuts/@dto/read.published_story";

import { ReadPublishedReviewEntity } from "@6cuts/@dto/read.published_review";
import { ReadOwnUserPlayStatusEntity } from "@6cuts/@dto/read.own-user";
import { UserPlayAction } from "@6cuts/@dto/write.user";
import { FSWriteUserPlayStatusRepository } from "@6cuts/public-repository/write.user.play_status";
import { ChapterActionType } from "@6cuts/@dto/write.story";
import { FSReadOwnUserPlayStatusRepository } from "@6cuts/public-repository/read.own_user.play_status";
import { take } from "rxjs/operators";
import { FSWriteEventRepository } from "@6cuts/public-repository/write.event";
import { CSStoryImageRepository } from "@6cuts/storage/story/image";
import { Action } from "rxjs/internal/scheduler/Action";

interface NextChapterActionInfo {
  nextChapterId: string;
  point: number;
  action: UserPlayAction;
}

const useStoryContainer = (initialState = "") => {
  // とりあえずIDはベタガキ
  const storyId: string = initialState;
  // プレイデータを特定するためのID
  const playId: string = uuid4();
  // ストーリー
  const [story, setStory] = useState<ReadPublishedStoryEntity>();
  const [review, setReview] = useState<ReadPublishedReviewEntity>();
  // ストーリーの現在進行データ
  const [currentChapter, setCurrentChapter] = useState<
    ReadPublishedChapterEntity
  >();
  // 動画のダウンロードURL
  const [videoUrl, setVideoUrl] = useState<string>();
  // TOP画像のダウンロードURL
  const [titleImageUrl, setTitleImageUrl] = useState<string>();
  // スコアポイント
  const [scorePoint, setScorePoint] = useState<number>(0);
  // 追加されたポイント
  const [additionalPoint, setAdditionalPoint] = useState<number>(0);
  // ストーリー開始フラグ
  const [isStart, setIsStart] = useState<boolean>(false);
  // 動画再生中フラグ
  const [isPlayVideo, setIsPlayVideo] = useState<boolean>(false);
  // 動画終了フラグ
  const [isVideoEnd, setIsVideoEnd] = useState<boolean>(false);
  // 動画エレメント
  const videoEl = useRef<HTMLVideoElement>(null);
  // ストーリーダイアログの表示フラグ
  const [isEndDialog, setIsEndDialog] = useState<boolean>(false);
  // ストーリーが利用可能か
  const [isStoryAvailable, setIsStoryAvailable] = useState<boolean>(true);
  // リプレイ可能かどうか
  const [isReplayEnabled, setIsReplayEnabled] = useState<boolean>(true);
  // フルスクリーン切り替え
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  // 購入画面表示フラグ
  const [isShowPurchaseModal, setIsShowPurchaseModal] = useState<boolean>(
    false
  );
  //ログイン表示フラグ
  const [isShowLoginModal, setIsShowLoginModal] = useState<boolean>(false);
  // 購入画面表示フラグ
  const [isPurchased, setIsPurchased] = useState<boolean>(false);
  // 無料プレイ画面表示フラグ
  const [isShowFreePlayModal, setIsShowFreePlayModal] = useState<boolean>(
    false
  );
  const [isFreePlay, setIsFreePlay] = useState<boolean>(false);
  const [
    nextChapterActionInfo,
    setNextChapterActionInfo,
  ] = useState<NextChapterActionInfo | null>(null);

  //効果音
  const addPointAudioRef = useRef<HTMLAudioElement>(null);
  const subtractPointAudioRef = useRef<HTMLAudioElement>(null);

  // プレイ状況
  const [userId, setUserId] = useState<string | null>(null);
  const [isPlayStatusChecked, setIsPlayStatusChecked] = useState<boolean>(
    false
  );
  const [
    userPlayStatus,
    setUserPlayStatus,
  ] = useState<ReadOwnUserPlayStatusEntity | null>(null);

  useEffect(() => {
    const storySubscription = FSReadPublishedStoryRepository.watchPublishedStory(
      {
        storyId: initialState,
      }
    ).subscribe((storyData: ReadPublishedStoryEntity | null) => {
      if (storyData === null) {
        setIsStoryAvailable(false);
        return;
      }
      setStory(storyData);
      setCurrentChapter(storyData.chapterList[0]);

      if (storyData.scoreEnable) {
        setScorePoint(storyData.initScore);
      }

      //タイトル画像の取得
      setTitleImageUrl(
        CSStoryImageRepository.getPublicStoryImageUrl({
          storyId: storyData.id,
          imageId: storyData.thumbnailImage,
        })
      );
    });

    const reviewSubscription = FSReadPublishedReviewRepository.watchReview({
      storyId: initialState,
    }).subscribe((review: ReadPublishedReviewEntity | null) => {
      console.log(review);
      if (review !== null) {
        setReview(review);
      }
    });

    return () => {
      storySubscription.unsubscribe();
      reviewSubscription.unsubscribe();
    };
  }, [initialState]);

  useEffect(() => {
    if (storyId === "" || currentChapter === undefined || !isStart) {
      return;
    }
    setIsPlayVideo(true);
    setIsFullScreen(false);
    //動画の取得
    FSGetStorage.getStoryVideoUrl(
      storyId,
      currentChapter.sentenceList[0].mediaId
    ).then((url: string) => {
      setVideoUrl(url);
      console.log(url);
    });
  }, [currentChapter, storyId, isStart]);

  useEffect(() => {
    if (isStart) {
      //続きからじゃなければプレイカウントをインクリメント
      if (userPlayStatus === null) {
        IncrementPlay();
        if (isFreePlay) {
          IncrementFreePlay();
        }
      }
    }
  }, [isStart]);

  /**
   * プレイ中のデータを取得して状況をプレイ中データに合わせてセットする
   */
  useEffect(() => {
    if (!story || !storyId || !userId || isPlayStatusChecked) {
      return;
    }
    // プレイ中データの取得
    FSReadOwnUserPlayStatusRepository.watch({
      userId: userId,
      storyId: storyId,
    }).subscribe((entity: ReadOwnUserPlayStatusEntity | null) => {
      setIsPlayStatusChecked(true);
      if (entity !== null) {
        setUserPlayStatus(entity);
        setIsPurchased(entity.purchased);
      }
    });
  }, [story, storyId, userId, isPlayStatusChecked]);

  const initUserPlayStatus = () => {
    if (!story || userPlayStatus === null) {
      return;
    }
    setScorePoint(userPlayStatus.score);
    setIsFreePlay(userPlayStatus.isFreePlay);

    // 最後のチャプターアクションを取得
    const lastAction: UserPlayAction | null =
      userPlayStatus.actionList.length > 0
        ? userPlayStatus.actionList[userPlayStatus.actionList.length - 1]
        : null;
    if (lastAction === null) {
      return;
    }

    console.log("*** last action ***");
    console.log(lastAction);

    // 最後のチャプターアクションによる遷移先チャプターを取得
    const chapterList: ReadPublishedChapterEntity[] = story.chapterList.filter(
      (chapter: ReadPublishedChapterEntity) => {
        return chapter.chapterId === lastAction.nextChapterId;
      }
    );

    // 遷移先チャプターがあればセットする
    if (chapterList.length > 0) {
      console.log("*** next chapter ***");
      console.log(chapterList[0]);
      // setIsStart(true);
      setVideoUrl("");
      setCurrentChapter(chapterList[0]);
      setIsVideoEnd(false);
    }
  };

  //次のストーリーにいく
  const moveToNextChapter = (nextChapterId: string) => {
    if (story) {
      story.chapterList.some((chapter: ReadPublishedChapterEntity) => {
        if (chapter.chapterId === nextChapterId) {
          if (currentChapter === chapter) {
            //同じチャプターならリプレイ
            videoEl.current!.currentTime = 0;
            setIsFullScreen(false);
            setIsPlayVideo(true);
          } else {
            setVideoUrl("");
            setCurrentChapter(chapter);
          }
          //フラグを初期化
          setIsVideoEnd(false);
          return true;
        }
      });
    }
  };

  //スコアポイントの加算
  const addScorePoint = (amount: number) => {
    if (!story) {
      return;
    }
    if (!story.scoreEnable) {
      return;
    }

    setScorePoint((point: number) => point + amount);
    setAdditionalPoint(amount);
  };

  //再生中フラグの切り替え
  const switchPlayVideo = () => {
    setIsPlayVideo((isPlay: boolean) => !isPlay);
  };

  //フルスクリーン切り替え
  const switchFullScreen = () => {
    setIsFullScreen((isFullScreen: boolean) => !isFullScreen);
  };

  const resetPlayStatus = () => {
    updatePlayFinishedStatus(true);
    setUserPlayStatus(null);
  };

  //ストーリーの初期化
  const storyReset = () => {
    if (story) {
      setCurrentChapter(story.chapterList[0]);
      if (story.scoreEnable) {
        setScorePoint(story.initScore);
      }
    }
    setAdditionalPoint(0);
    setIsEndDialog(false);
    setIsStart(false);
    setIsVideoEnd(false);
    setIsFullScreen(false);
    setIsReplayEnabled(true);
    setIsPlayVideo(true);
    setIsShowPurchaseModal(false);
    setIsShowFreePlayModal(false);
    setIsShowLoginModal(false);
    setIsPurchased(false);
    setIsShowFreePlayModal(false);
    setNextChapterActionInfo(null);
    setIsFreePlay(false);
  };

  //スコアサウンドの再生
  const playScorePoint = (point: number) => {
    if (!story) {
      return;
    }
    if (!story.scoreEnable || !story.scoreVisible) {
      return;
    }
    if (point > 0) {
      playAddPointSound();
    } else if (point < 0) {
      playSubtractPointSound();
    } else {
    }
  };

  const playAddPointSound = () => {
    if (addPointAudioRef.current) {
      addPointAudioRef.current.load();
      addPointAudioRef.current.play();
    }
  };
  const playSubtractPointSound = () => {
    if (subtractPointAudioRef.current) {
      subtractPointAudioRef.current.load();
      subtractPointAudioRef.current.play();
    }
  };

  const setReplayDisabled = () => {
    setIsReplayEnabled(false);
  };

  const isMoveNextStoryEnabled = (): boolean => {
    if (!story) {
      return true;
    }
    if (!currentChapter) {
      return true;
    }
    if (story.price === 0) {
      return true;
    }

    if (story.billingChapterId !== currentChapter.chapterId) {
      // 課金チャプターでなければそのまま次へ
      return true;
    } else {
      // 課金チャプターなら課金済かどうかをチェック
      return isPurchased;
    }
  };

  /**
   * ストーリーアクションを追加する
   * @param action
   */
  const saveUserPlayAction = async (action: UserPlayAction, point: number) => {
    // 生成する
    if (userId === null) {
      return;
    }

    if (userPlayStatus === null) {
      // データがない場合は生成
      let status: ReadOwnUserPlayStatusEntity | null = userPlayStatus;
      const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
      const playId: string = await FSWriteUserPlayStatusRepository.create({
        userId: userId,
        storyId: storyId,
        score: scorePoint + point,
        actionList: [action],
        purchased: false,
        purchaseId: "",
        isFreePlay: isFreePlay,
        ended: action.type === "end" ? true : false,
      });

      status = {
        id: playId,
        userId: userId,
        storyId: storyId,
        score: scorePoint + point,
        actionList: [action],
        purchased: false,
        purchaseId: "",
        ended: action.type === "end" ? true : false,
        isFreePlay: isFreePlay,
        created: now,
        updated: now,
      };
      setUserPlayStatus(status);
    } else if (userPlayStatus) {
      // データがある場合は更新
      let status: ReadOwnUserPlayStatusEntity = userPlayStatus;
      const exist: boolean = status.actionList.some((_action) => {
        return _action.type === ChapterActionType.END;
      });
      // 存在してたら終了
      if (exist) {
        return;
      }

      // 存在してなかったら生成
      status.actionList.push(action);
      if (action.type === "end") {
        status.ended = true;
      }
      await FSWriteUserPlayStatusRepository.updatePlayAction({
        playId: status.id,
        userId: userId,
        storyId: storyId,
        score: scorePoint + point,
        actionList: status.actionList,
        ended: action.type === "end" ? true : false,
      });
      setUserPlayStatus(status);
    }
  };

  /**
   * プレイ状況の終了フラグを更新する
   * @param finished
   */
  const updatePlayFinishedStatus = async (finished: boolean) => {
    if (userPlayStatus && userId) {
      const status = userPlayStatus;
      status.ended = finished;
      await FSWriteUserPlayStatusRepository.updateFinishedStatus({
        playId: status.id,
        userId: userId,
        finished: finished,
      });
    }
  };

  /**
   * プレイ状況の購入状態を更新する
   * @param purchased
   */
  const updatePurchasedStatus = async (purchased: boolean) => {
    if (userPlayStatus && userId) {
      const status = userPlayStatus;
      status.purchased = purchased;

      await FSWriteUserPlayStatusRepository.updatePurchaseStatus({
        playId: status.id,
        userId: userId,
        purchased: purchased,
      });
      setUserPlayStatus(status);
    }
  };

  const IncrementPlay = async () => {
    await FSWriteEventRepository.createStoryPlayEvent({
      storyId: story!.id,
      playId: playId,
    });
  };

  const IncrementFreePlay = async () => {
    await FSWriteEventRepository.createStoryFreePlayEvent({
      storyId: story!.id,
      playId: playId,
    });
    setIsShowFreePlayModal(false);
  };

  const IncrementPurchase = async () => {
    await FSWriteEventRepository.createStoryPurchase({
      storyId: story!.id,
      playId: playId,
    });
    setIsShowFreePlayModal(false);
  };

  return {
    story,
    playId,
    currentChapter,
    videoUrl,
    titleImageUrl,
    scorePoint,
    additionalPoint,
    isStart,
    isPlayVideo,
    isVideoEnd,
    isEndDialog,
    videoEl,
    isStoryAvailable,
    isReplayEnabled,
    isFullScreen,
    addPointAudioRef,
    subtractPointAudioRef,
    review,
    isShowPurchaseModal,
    isPurchased,
    isShowFreePlayModal,
    isShowLoginModal,
    nextChapterActionInfo,
    isPlayStatusChecked,
    userPlayStatus,
    isFreePlay,
    moveToNextChapter,
    addScorePoint,
    setIsVideoEnd,
    switchPlayVideo,
    switchFullScreen,
    setIsEndDialog,
    setIsStart,
    storyReset,
    setReplayDisabled,
    playScorePoint,
    setIsShowPurchaseModal,
    isMoveNextStoryEnabled,
    setIsPurchased,
    setIsShowFreePlayModal,
    setIsShowLoginModal,
    setUserId,
    setNextChapterActionInfo,
    saveUserPlayAction,
    updatePurchasedStatus,
    updatePlayFinishedStatus,
    IncrementPlay,
    IncrementFreePlay,
    IncrementPurchase,
    setIsPlayVideo,
    setIsFreePlay,
    resetPlayStatus,
    initUserPlayStatus,
  };
};

export const StoryContainer = createContainer(useStoryContainer);

import React, { useState, ReactEventHandler } from "react";
import styles from "./page.module.scss";
import { AuthService } from "@6cuts/auth/auth-service";
import { useHistory } from "react-router-dom";
import { HeaderComponent } from "components/common/header/component";
import { AppContainer } from "containers/app/app.container";
import { LinkService } from "@6cuts/auth/link-service";

/*
 * パスワード忘れ
 */
export const PasswordForgetPageComponent: React.FC = () => {
  const history = useHistory();
  const appContainer = AppContainer.useContainer();

  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSentEmail, setIsSentEmail] = useState<boolean>(false);

  const onChangeEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onClickSendMailHandler = async () => {
    setIsSaving(true);
    AuthService.sendPasswordResetEmail({ email: email }).then((_) => {
      setIsSaving(false);
      setIsSentEmail(true);
    }).catch((error) => {
      const message: string = error.message
        ? error.message
        : "メール送信に失敗しました。通信状況をお確かめの上、再度お試し下さい。";
      if (error.code) {
        switch (error.code) {
          case "auth/invalid-email":
            setErrorMessage("正しいメールアドレスを入力してください。");
            break;
          default:
            setErrorMessage(
              "メールアドレスが間違っています。"
            );
            break;
        }
      } else {
        setErrorMessage(message);
      }
      setIsSaving(false);
    });
  };

  if (isSentEmail) {
    return (
      <div className={styles.page}>
        <HeaderComponent
          backEnabled={true}
          title="サインアップ"
        ></HeaderComponent>

        <section className="section">
          <div className="container">
            <div>入力したメールアドレスにメールを送信しました。</div>
            <div>メール内のURLを開いてパスワードの変更を行ってください。</div>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <HeaderComponent
        backEnabled={true}
        title="サインアップ"
      ></HeaderComponent>

      <section className="section">
        <div className="container">
          <div className="field is-vertical">
            <p>6cutsに登録しているメールアドレスを入力してください</p>
            <div className="field-label is-normal">
              <label className="label">メールアドレス</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="email"
                    placeholder="Email"
                    onChange={onChangeEmailHandler}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="error-message has-text-danger">{errorMessage}</div>
          <div className="action">
            <div>
              <button
                className="button is-outlined sign-in"
                disabled={email.length <= 0}
                onClick={onClickSendMailHandler}
              >
                送信
              </button>
            </div>
          </div>
        </div>
      </section>

      {isSaving && (
        <div className="saving-mask">
          <button className="loading button is-loading is-text"></button>
        </div>
      )}
    </div>
  );
};

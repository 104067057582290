import React, { useEffect, useState } from "react";
import styles from "./page.module.scss";
import { HeaderComponent } from "components/common/header/component";
import { FSReadPublishedStoryRepository } from "@6cuts/public-repository/read.published_story";
import { ReadStoryEntity } from "@6cuts/@dto/read.story";
import { HomeStoryCardComponent } from "./story-card-component";
import { FSConfigRepository } from "@6cuts/public-repository/config";
import { ReadPublishedStoryEntity } from "@6cuts/@dto/read.published_story";

/*
 * Home画面 (オススメストーリー)
 */
export const HomePageComponent: React.FC = () => {
  const [storyList, setStoryList] = useState<ReadPublishedStoryEntity[]>([]);

  useEffect(() => {
    // TODO: イチオシストーリーID取得
    FSConfigRepository.getRecommendStoryIdList().then(
      (storyIdList: string[]) => {
        const _storyList: ReadPublishedStoryEntity[] = [];

        console.log("*****");
        console.log(storyIdList);

        const promiseList: Promise<void>[] = [];
        storyIdList.forEach((storyId: string) => {
          promiseList.push(
            FSReadPublishedStoryRepository.get({ storyId: storyId }).then(
              (storyEntity: ReadPublishedStoryEntity | null) => {
                if (storyEntity !== null) {
                  _storyList.push(storyEntity);
                }
              }
            )
          );
        });

        Promise.all(promiseList).then(() => {
          setStoryList(_storyList);
        });
      }
    );
  }, []);

  return (
    <div className={styles.page}>
      <HeaderComponent backEnabled={false}></HeaderComponent>
      <h1 className="is-size-6 has-text-centered has-text-weight-bold">
        イチオシストーリー
      </h1>

      <div className="storyList">
        {storyList.map((story: ReadPublishedStoryEntity) => {
          return <HomeStoryCardComponent key={story.id} story={story} />;
        })}
      </div>
    </div>
  );
};

import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Route, Link, Redirect, Switch } from "react-router-dom";

import "environment/firebase_env";
import "environment/storage_env";

import styles from "./app.module.scss";
import { StoryComponent } from "./components/story/page";
import { StoryEditPageComponent } from "./components/story-edit/story-edit-page.component";
import { AppContainer } from "containers/app/app.container";

import { AuthService } from "@6cuts/auth/auth-service";
import { FSConfigRepository } from "@6cuts/public-repository/config";

import { authState } from "rxfire/auth";
import firebase from "firebase";
import { take } from "rxjs/operators";
import { UnlinkedRoute, LinkedRoute } from "./route";
import { PortalPageComponent } from "./components/portal/page";
import { SignInEmailPageComponent } from "./components/sign-in/email-sign-in/page";
import { SignUpEmailPageComponent } from "./components/sign-in/email-sign-up/page";
import { StoryGatePageComponent } from "./components/story-gate/page";
import { SignInTopPageComponent } from "./components/sign-in/sign-in-top/page";
import { SignUpTopPageComponent } from "./components/sign-in/sign-up-top/page";
import { AccountEditPageComponent } from "./components/account/edit/page";
import { HomePageComponent } from "components/home/page";
import { AccountTopPageComponent } from "components/account/top/page";
import { AccountOtherPageComponent } from "components/account/other/page";
import { AccountPlayHistoryPageComponent } from "components/account/play-history/page";
import { AccountPurchaseHistoryPageComponent } from "components/account/purchase-history/page";
import { AccountPurchaseDetailPageComponent } from "components/account/purchase-detail/page";
import { ComebackPageComponent } from "components/comeback/page";
import { PrivacyPolicyPageComponent } from "components/terms/privacy-policy/page";
import { SpecificCommercePageComponent } from "components/terms/specific-commerce/page";
import { TermsOfServicePageComponent } from "components/terms/terms-of-service/page";
import { ContactPageComponent } from "components/account/contact/page";
import { PasswordForgetPageComponent } from "./components/sign-in/email-password-forget/page";
import { PayWithAmazonPageComponent } from "./components/common/purchase/amazon_pay/confirm/page";

export const App: React.FC = () => {
  return (
    <AppContainer.Provider>
      <AppComponent />
    </AppContainer.Provider>
  );
};

export const AppComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();

  // ウィンドウの表示領域サイズ (PC表示サイズ調整用)
  const width = window.innerWidth;
  const height = window.innerHeight;

  /**
   * 広告IDから表示された場合
   * 対象のStoryIDを取得してリダイレクトする
   * @param advertisementId
   */
  const redirectForAdvertisement = (advertisementId: string) => {
    FSConfigRepository.getRedirectUrlFromAdvertisementId({
      advertisementId: advertisementId,
    }).subscribe((redirectUrl: string | null) => {
      if (redirectUrl === null) {
        window.location.href = "/story/unknown";
      } else {
        window.location.href = redirectUrl;
      }
    });
    return <div></div>;
  };
  // 認証状況取得中 または ユーザ情報取得中 Loadingを表示
  if (
    appContainer.isAuth === null ||
    (appContainer.isAuth === true && appContainer.user === null)
  ) {
    return <div></div>;
  }

  console.log(appContainer.isAuth);
  const routing = () => {
    return (
      <BrowserRouter>
        <Switch>
          {/* 本ログイン、ゲストログインのみ表示されるページ */}
          <LinkedRoute
            exact
            path="/account"
            component={AccountTopPageComponent}
          />
          <LinkedRoute
            exact
            path="/account/other"
            component={AccountOtherPageComponent}
          />
          <LinkedRoute
            exact
            path="/account/edit"
            component={AccountEditPageComponent}
          />
          <LinkedRoute
            exact
            path="/account/play/history"
            component={AccountPlayHistoryPageComponent}
          />
          <LinkedRoute
            exact
            path="/account/purchase/history"
            component={AccountPurchaseHistoryPageComponent}
          />
          <LinkedRoute
            exact
            path="/account/purchase/detail/:purchaseId"
            component={AccountPurchaseDetailPageComponent}
          />
          <LinkedRoute
            exact
            path="/comeback"
            component={ComebackPageComponent}
          />
          <LinkedRoute
            path="/story/edit/:pageId/:xxxId"
            component={StoryEditPageComponent}
          />
          <LinkedRoute exact path="/" component={HomePageComponent} />
          <LinkedRoute
            exact
            path="/account/contact"
            component={ContactPageComponent}
          />
          {/* 未ログイン、ゲストログインのみ表示されるページ */}
          <UnlinkedRoute exact path="/portal" component={PortalPageComponent} />
          <UnlinkedRoute
            exact
            path="/sign-in"
            component={SignInTopPageComponent}
          />
          <UnlinkedRoute
            exact
            path="/sign-in/email"
            component={SignInEmailPageComponent}
          />
          <UnlinkedRoute
            exact
            path="/sign-in/email/forget-password"
            component={PasswordForgetPageComponent}
          />
          <UnlinkedRoute
            exact
            path="/sign-up/email"
            component={SignUpEmailPageComponent}
          />
          <UnlinkedRoute
            exact
            path="/sign-up"
            component={SignUpTopPageComponent}
          />

          {/* どちらでも表示されるページ */}
          <Route
            exact
            path="/_story/:storyId"
            render={(props) => (
              <Redirect to={`/story/${props.match.params.storyId}`} />
            )}
          />
          <Route
            exact
            path="/ad/:adId"
            render={(props) =>
              redirectForAdvertisement(props.match.params.adId)
            }
          />
          <Route
            exact
            path="/terms/privacy-policy"
            component={PrivacyPolicyPageComponent}
          />
          <Route
            exact
            path="/terms/terms-of-service"
            component={TermsOfServicePageComponent}
          />
          <Route
            exact
            path="/terms/specific-commerce"
            component={SpecificCommercePageComponent}
          />
          {/*　※特殊※　ストーリーは直接切り替える */}
          <Route
            exact
            path="/story/:storyId"
            component={
              appContainer.isAuth ? StoryComponent : StoryGatePageComponent
            }
          />
          <Route
            exact
            path="/pay-with-amazon/:transactionId"
            component={PayWithAmazonPageComponent}
          />
        </Switch>
      </BrowserRouter>
    );
  };

  if (width <= 768) {
    // モバイル
    return <div className={styles.mobile}>{routing()}</div>;
  } else {
    // モバイル以外 (中央にコンテンツを縦長表示)
    const contentWidth: string = `${(height * 0.8 * 1080) / 1920}px`;
    const contentHeight: string = `${height * 0.8}px`;
    const top: string = `calc(50% - ${(height * 0.8) / 2}px)`;
    const left: string = `calc(50% - ${(height * 0.8 * 1080) / 1920 / 2}px)`;
    const isScrollDisabled: boolean =
      window.location.pathname.startsWith("/story/") ||
      window.location.pathname.startsWith("/public-story/");
    const contentStyle = {
      top: top,
      left: left,
      width: contentWidth,
      height: contentHeight,
    };

    return (
      <div className={styles.not_mobile}>
        <img className="background-image" src="/assets/images/bg_01.png"></img>
        <div
          className={`contents ${isScrollDisabled ? "" : "scrollable"}`}
          style={contentStyle}
        >
          {routing()}
        </div>
      </div>
    );
  }
};

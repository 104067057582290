import React, { useEffect } from "react";
import styles from "./page.module.scss";
import { take } from "rxjs/operators";

import { useHistory } from "react-router";

import { AppContainer } from "../../containers/app/app.container";

import { AuthService } from "@6cuts/auth/auth-service";
// import { FSReadOwnUserRepository } from "@6cuts/repository/read.own_user";
import { ReadOwnUserEntity } from "@6cuts/@dto/read.own-user";
import { Link } from "react-router-dom";

/*
 * サインイン
 */
export const PortalPageComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const history = useHistory();

  useEffect(() => {
    AuthService.checkRedirectResult().then((user: firebase.User | null) => {
      if (user === null) {
        return;
      }
    });
  });

  const onClickLoginHandler = () => {
    history.push("/sign-in");
  };

  return (
    <div className={styles.page}>
      <div className="black-mask"></div>
      <div className="logo">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/6cuts-icon_logotxt.png`}
        ></img>
      </div>

      <div className="sign-in">
        <div className="login">
          <button
            className="twitter button is-rounded"
            onClick={onClickLoginHandler}
          >
            <span className="text">ログイン</span>
          </button>
          <div className="terms-of-service has-text-centered is-size-7">
            <Link to="/terms/terms-of-service" target="_blank">
              <span className="has-text-white">- 利用規約 -</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react"
import styles from "./page.module.scss"
import { AppContainer } from "containers/app/app.container"
import { useHistory } from "react-router"
import { HeaderComponent } from "components/common/header/component"
import { ReadOwnUserPruchaseEntity } from "@6cuts/@dto/read.own-user"
import { FSReadOwnUserPurchaseRepository } from "@6cuts/public-repository/read.own_user.purchase"
import { AccountPurchaseHistoryListCellComponent } from "./purchase-list-cell"


/*
 * アカウント管理画面トップ
 */
export const AccountPurchaseHistoryPageComponent: React.FC = () => {
    const appContainer = AppContainer.useContainer()
    const history = useHistory()

    const [purchaseList, setPurchaseList] = useState<ReadOwnUserPruchaseEntity[]>([])

    useEffect(() => {
        if (appContainer.userId === null) {
            return
        }

        FSReadOwnUserPurchaseRepository.getList({ userId: appContainer.userId })
            .then((entityList: ReadOwnUserPruchaseEntity[]) => {
                setPurchaseList(entityList)
            })
    }, [appContainer.userId])

    return (
        <div className={styles.page}>
            <HeaderComponent
                backEnabled={true}
                backTo="/account"
                title="決済履歴"
            ></HeaderComponent>

            {
                purchaseList.map((purchase: ReadOwnUserPruchaseEntity) => {
                    return (
                        <AccountPurchaseHistoryListCellComponent
                            purchase={purchase}
                        ></AccountPurchaseHistoryListCellComponent>
                    )
                })
            }

        </div>
    )
}

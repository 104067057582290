import React, { useEffect, useState } from "react";
import styles from "./page.module.scss";
import { StoryContainer } from "./story.container";
import { StoryTopComponent } from "./story-top/story-top-component";
import { StoryMainComponent } from "./story-main/story-main.component";
import { useParams } from "react-router-dom";
import { LoadingPageComponent } from "components/loading/page";
import { AppContainer } from "containers/app/app.container";

/*
 * ストーリーページ本体
 */
export const StoryComponent: React.FC = () => {
  const { storyId } = useParams();

  return (
    <StoryContainer.Provider initialState={storyId}>
      <StoryOperate />
    </StoryContainer.Provider>
  );
};

const StoryOperate: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const storyContainer = StoryContainer.useContainer();

  const [isLoadingHidden, setIsLoadingHidden] = useState<boolean>(false);
  const [isMakuakeAnimationHidden, setIsMakuakeAnimationHidden] = useState<
    boolean
  >(true);
  const [
    isStoryButtonAnimationStart,
    setIsStoryButtonAnimationStart,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (appContainer.userId) {
      storyContainer.setUserId(appContainer.userId);
    }
  }, [appContainer.userId]);

  const onMakuakeAnimationEnd = () => {
    setIsMakuakeAnimationHidden(true);
    setIsStoryButtonAnimationStart(true);
  };

  // ------------------------------------------------------------
  //
  // LoadingPageComponent Callback
  //
  // ------------------------------------------------------------
  const loadingAnimationIteration = () => {};

  const onCompletedLoadingHideAnimation = () => {
    setIsLoadingHidden(true);
    setIsMakuakeAnimationHidden(false);
  };

  if (storyContainer.isStoryAvailable === false) {
    return (
      <div className={styles.no_content}>
        <div className="black-mask">
          <div className="story-not-available">
            <p className="subtitle has-text-white">このコンテンツは</p>
            <p className="subtitle has-text-white">現在利用できません</p>
          </div>
        </div>
      </div>
    );
  }
  if (storyContainer.isStart) {
    return <StoryMainComponent />;
  } else {
    return (
      <div className={styles.page}>
        {storyContainer.story !== null && (
          <div
            className={`story-top ${
              !isMakuakeAnimationHidden && isLoadingHidden ? "anim" : ""
            }`}
          >
            <StoryTopComponent canStart={isStoryButtonAnimationStart} />
          </div>
        )}
        {isLoadingHidden && !isMakuakeAnimationHidden && (
          <div
            className="black-mask"
            onAnimationEnd={onMakuakeAnimationEnd}
          ></div>
        )}
        {!isLoadingHidden && (
          <LoadingPageComponent
            loadingFinished={storyContainer.isPlayStatusChecked}
            onAnimationIteration={loadingAnimationIteration}
            onCompletedHideAnimation={onCompletedLoadingHideAnimation}
          ></LoadingPageComponent>
        )}
      </div>
    );
  }
};

import React, { useState, useEffect, useRef } from "react";
import styles from "./page.module.scss";
import { AppContainer } from "containers/app/app.container";
import { useHistory } from "react-router";
import { CSUserImageRepository } from "@6cuts/storage/users/image";
import { ReadOwnUserEntity } from "@6cuts/@dto/read.own-user";
import { FSReadOwnUserRepository } from "@6cuts/public-repository/read.own_user";
import { Gender } from "@6cuts/@dto/write.user";
import { FSWriteUserRepository } from "@6cuts/public-repository/write.user";
import { HeaderComponent } from "components/common/header/component";
import { AuthService } from "@6cuts/auth/auth-service";
import { Link } from "react-router-dom";

interface User {
  id: string;
  nickname: string;
  isExistIcon: boolean;
  gender: string;
  birthday: string;
}

/*
 * アカウント管理画面トップ
 */
export const AccountOtherPageComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const history = useHistory();

  const onClickContactHandler = () => {
    history.push("/account/contact");
  };

  return (
    <div className={styles.page}>
      <HeaderComponent backEnabled={true} backTo="/"></HeaderComponent>
      <section>
        <Link to="/terms/terms-of-service" target="_blank">
          <div className="action">
            <span>利用規約</span>
            <i className="fas fa-chevron-right"></i>
          </div>
        </Link>
        <Link to="/terms/privacy-policy" target="_blank">
          <div className="action">
            <span>プライバシーポリシー</span>
            <i className="fas fa-chevron-right"></i>
          </div>
        </Link>
      </section>
      <section>
        <div className="action" onClick={onClickContactHandler}>
          <span>お問い合わせ</span>
          <i className="fas fa-chevron-right"></i>
        </div>
      </section>
    </div>
  );
};

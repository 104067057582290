import React, { useState, useEffect, useRef } from "react";
import styles from "./component.module.scss";

import {
  UserPurchaseItemType,
  UserPurchaseState,
  UserPurchasePaymentType,
} from "@6cuts/@dto/write.user";
import { AppContainer } from "containers/app/app.container";
import { FSWriteUserPurchaseEventRepository } from "@6cuts/public-repository/write.user.purchase_event";
import { FSReadOwnUserPurchaseRepository } from "@6cuts/public-repository/read.own_user.purchase";
import { ReadOwnUserPruchaseEntity } from "@6cuts/@dto/read.own-user";
import { ReadPublishedStoryEntity } from "@6cuts/@dto/read.published_story";
import { Link } from "react-router-dom";
import { AmazonPayButton } from "./amazon_pay/amazon_pay_button/component";
import { StoryContainer } from "components/story/story.container";
import { v4 as uuidv4 } from "uuid";

declare const paypal: any;

export interface PurchaseComponentProps {
  story: ReadPublishedStoryEntity;
  onCloseHandler: () => void;
  onPurchaseCompletedHandler: () => void;
}

/*
 * 購入
 */
export const PurchaseComponent: React.FC<PurchaseComponentProps> = (
  props: PurchaseComponentProps
) => {
  const appContainer = AppContainer.useContainer();
  const storyContainer = StoryContainer.useContainer();
  console.log(appContainer);

  const userId: string = appContainer.userId!;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isOtherPayment, setIsOtherPayment] = useState<boolean>(false);

  const paypalEl = useRef<HTMLDivElement>(null);
  const amazonPayTransactionId: string = uuidv4();

  /**
   * PayPal購入処理
   * 注文データ作成 -> 購入前処理終了 -> 購入処理終了
   */
  useEffect(() => {
    if (!isOtherPayment) {
      return;
    }
    let transactionId: string | null = null;
    paypal
      .Buttons({
        style: {
          shape: "rect",
          color: "gold",
          layout: "vertical",
          label: "paypal",
        },
        createOrder: async (data: any, actions: any) => {
          // 注文データを作成する
          transactionId = await FSWriteUserPurchaseEventRepository.createOrderEvent(
            {
              userId: userId,
              itemType: UserPurchaseItemType.story,
              itemId: props.story.id,
              itemName: props.story.title,
              orderId: "",
              paymentType: UserPurchasePaymentType.paypal,
              price: props.story.price,
              data: {
                details: JSON.stringify(data),
              },
            }
          ).catch((error) => {
            console.log(error);
            return null;
          });

          if (transactionId === null) {
            return;
          }

          // PayPalに注文データを作成
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: "JPY",
                  value: `${props.story.price}`,
                },
                custom_id: transactionId,
              },
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
          });
        },
        onApprove: (data: any, actions: any) => {
          console.log("onApprove data:");
          console.log(data);
          setIsProcessing(true);

          // Approveデータを作成
          FSWriteUserPurchaseEventRepository.createOtherEvent({
            userId: userId,
            itemType: UserPurchaseItemType.story,
            itemId: props.story.id,
            itemName: props.story.title,
            transactionId: transactionId!,
            orderId: data.orderID,
            paymentType: UserPurchasePaymentType.paypal,
            price: props.story.price,
            status: UserPurchaseState.approve,
            data: {
              orderId: data.orderID,
              payerId: data.payerID,
              details: JSON.stringify(data),
            },
          });

          // PayPal決済実行
          return actions.order.capture().then(function (details: any) {
            console.log("paypal capture details:");
            console.log(details);
            console.log(details.purchase_units[0].payments[0]);

            // 決済データを作成
            FSWriteUserPurchaseEventRepository.createOtherEvent({
              userId: userId,
              itemType: UserPurchaseItemType.story,
              itemId: props.story.id,
              itemName: props.story.title,
              transactionId: transactionId!,
              orderId: data.orderID,
              paymentType: UserPurchasePaymentType.paypal,
              price: props.story.price,
              status: UserPurchaseState.complete,
              data: {
                paypalTransactionId:
                  details.purchase_units[0].payments.captures[0].id,
                status: details.purchase_units[0].payments.captures[0].status,
                amount: details.purchase_units[0].payments.captures[0].amount,
                details: JSON.stringify(details),
              },
            });

            const subscription = FSReadOwnUserPurchaseRepository.watch({
              userId: userId,
              transactionId: transactionId!,
            }).subscribe((entity: ReadOwnUserPruchaseEntity | null) => {
              if (entity !== null) {
                if (entity.status === UserPurchaseState.complete) {
                  setIsProcessing(false);
                  subscription.unsubscribe();
                  props.onPurchaseCompletedHandler();
                } else if (entity.status === UserPurchaseState.cancel) {
                  subscription.unsubscribe();
                  setIsProcessing(false);
                }
              }
            });
          });
        },
        onCancel: async (data: any, actions: any) => {
          // キャンセルデータを作成
          await FSWriteUserPurchaseEventRepository.createOtherEvent({
            userId: userId,
            itemType: UserPurchaseItemType.story,
            itemId: props.story.id,
            itemName: props.story.title,
            transactionId: transactionId!,
            orderId: data.orderID,
            paymentType: UserPurchasePaymentType.paypal,
            price: props.story.price,
            status: UserPurchaseState.cancel,
            data: {
              details: JSON.stringify(data),
            },
          });
          setIsProcessing(false);
        },
      })
      .render("#paypal-button-container");
  }, [isOtherPayment]);

  const onClickCloseModalHandler = () => {
    props.onCloseHandler();
  };

  const onClickOtherPaymentHandler = () => {
    setIsOtherPayment(!isOtherPayment);
  };

  const onClickAmazonPayHandler = () => {
    console.log("createOtherEvent");
    // AmazonPayボタンを選択時にデータを作成
    FSWriteUserPurchaseEventRepository.createOtherEvent({
      userId: userId,
      itemType: UserPurchaseItemType.story,
      itemId: props.story.id,
      itemName: props.story.title,
      transactionId: amazonPayTransactionId,
      orderId: "",
      paymentType: UserPurchasePaymentType.amazonPay,
      price: props.story.price,
      status: UserPurchaseState.select,
      data: {
        nextChapterActionInfo: {
          action: storyContainer.nextChapterActionInfo?.action,
          point: storyContainer.nextChapterActionInfo?.point,
          nextChapterId: storyContainer.nextChapterActionInfo?.nextChapterId,
        },
      },
    });
  };

  return (
    <div className={styles.component}>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-content">
          <section className="hero">
            <div className="has-text-white has-text-centered is-size-6">
              決済をして続きをお楽しみください
            </div>
            <div className="price has-text-white has-text-centered has-text-weight-bold">
              <span className="price is-size-3">¥{props.story.price}</span>
              <span className="per-play">/PLAY</span>
            </div>
            {!isOtherPayment && (
              <div
                className="amazonPayWidget"
                onClick={onClickAmazonPayHandler}
              >
                <AmazonPayButton transactionId={amazonPayTransactionId} />
              </div>
            )}
            {isOtherPayment && (
              <div>
                <div id="paypal-button-container" ref={paypalEl}></div>
                <div className="has-text-white has-text-centered is-size-7">
                  安心安全のお支払いサービスPayPal採用
                </div>
              </div>
            )}

            <div className="other-payment">
              <button
                className="button is-text is-normal has-text-white"
                onClick={onClickOtherPaymentHandler}
              >
                他の支払い方法
              </button>
            </div>
          </section>
        </div>
        <div className="tokusho has-text-centered is-size-7">
          <Link to="/terms/specific-commerce" target="_blank">
            <span className="has-text-white">- 特定商取引法に基づく表記 -</span>
          </Link>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={onClickCloseModalHandler}
        ></button>

        {isProcessing && (
          <div className="processing">
            <div className="black-mask"></div>
            <div className="loading-icon">
              <button className="loading button is-loading is-text"></button>
              <div className="has-text-white has-text-centered is-size-7">
                処理中...
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import styles from "./page.module.scss";
import { AppContainer } from "containers/app/app.container";
import { useHistory } from "react-router";
import { CSUserImageRepository } from "@6cuts/storage/users/image";
import { ReadOwnUserEntity } from "@6cuts/@dto/read.own-user";
import { FSReadOwnUserRepository } from "@6cuts/public-repository/read.own_user";
import { Gender } from "@6cuts/@dto/write.user";
import { FSWriteUserRepository } from "@6cuts/public-repository/write.user";
import { HeaderComponent } from "components/common/header/component";
import { AuthService } from "@6cuts/auth/auth-service";

interface User {
  id: string;
  nickname: string;
  isExistIcon: boolean;
  gender: string;
  birthday: string;
}

/*
 * アカウント管理画面トップ
 */
export const AccountTopPageComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const history = useHistory();

  const onClickPlayHistoryHandler = () => {
    history.push("/account/play/history");
  };

  const onClickPurchaseHistoryHandler = () => {
    history.push("/account/purchase/history");
  };

  const onClickAccountEditHandler = () => {
    history.push("/account/edit");
  };

  const onClickSignOutHandler = async () => {
    await appContainer.singOut();
    history.push("/portal");
  };

  const onClickOtherButtonHandler = () => {
    history.push("/account/other");
  };

  return (
    <div className={styles.page}>
      <HeaderComponent backEnabled={true} backTo="/"></HeaderComponent>

      <div className="nickname is-size-4 has-text-weight-bold">
        {appContainer.user?.nickname}
      </div>

      <section>
        <div className="action" onClick={onClickPlayHistoryHandler}>
          <span>プレイ履歴</span>
          <i className="fas fa-chevron-right"></i>
        </div>
        <div className="action" onClick={onClickPurchaseHistoryHandler}>
          <span>決済履歴</span>
          <i className="fas fa-chevron-right"></i>
        </div>
      </section>

      <section>
        <div className="action" onClick={onClickAccountEditHandler}>
          <span>アカウント設定</span>
          <i className="fas fa-chevron-right"></i>
        </div>
        <div className="action" onClick={onClickSignOutHandler}>
          <span>ログアウト</span>
          <i className="fas fa-chevron-right"></i>
        </div>
      </section>

      <section>
        <div className="action" onClick={onClickOtherButtonHandler}>
          <span>その他</span>
          <i className="fas fa-chevron-right"></i>
        </div>
      </section>
    </div>
  );
};

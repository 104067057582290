import React from "react";
import { StoryContainer } from "../story.container";
import "./story-main.component.scss";
import { StorySelectComponent } from "./story-select/story-select.component";
import { StoryKeywordComponent } from "./story-keyword/story-keyword.component";
import { StoryAddPointComponent } from "./story-add-point/story-add-point.component";
import { StoryResultComponent } from "./story-result/story-result-component";
import { StoryVideoComponent } from "./story-video/story-video.component";
import { StoryHeaderComponent } from "./story-header/story-header.component";
import { StorySoundComponent } from "./story-sound/story-sound.component";
import { StoryFreePlayComponent } from "./story-free-play/story-free-play";
import { StoryLoginModalComponent } from "./story-login-modal/story-login-modal";
import { YesNoAlertComponent } from "../../common/alert/alert";
import { PurchaseComponent } from "components/common/purchase/component";
import { ChapterActionType } from "@6cuts/@dto/write.story";

/*
 * ストーリーメイン
 */
export const StoryMainComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  return (
    <div className="container">
      <StoryVideoComponent />
      <StoryAddPointComponent />
      <StoryActionComponent />
      <StoryHeaderComponent />
      <StoryExplanationTextComponent />
      <StoryEndDialog />
      <StorySoundComponent />
      {storyContainer.isShowLoginModal && <StoryLoginModalComponent />}
      {storyContainer.isShowFreePlayModal && <StoryFreePlayComponent />}
      {storyContainer.isShowPurchaseModal && (
        <PurchaseComponent
          story={storyContainer.story!}
          onCloseHandler={() => {
            storyContainer.setIsShowPurchaseModal(false);
          }}
          onPurchaseCompletedHandler={() => {
            storyContainer.setIsShowPurchaseModal(false);
            storyContainer.setIsPurchased(true);
            storyContainer.IncrementPurchase();
            if (storyContainer.nextChapterActionInfo) {
              storyContainer.updatePurchasedStatus(true);
              storyContainer.saveUserPlayAction(
                storyContainer.nextChapterActionInfo.action,
                storyContainer.nextChapterActionInfo.point
              );
              storyContainer.addScorePoint(
                storyContainer.nextChapterActionInfo.point
              );
              storyContainer.moveToNextChapter(
                storyContainer.nextChapterActionInfo.nextChapterId
              );
              storyContainer.playScorePoint(
                storyContainer.nextChapterActionInfo.point
              );
            }
          }}
        />
      )}
    </div>
  );
};

/*
 * ストーリーアクションコンポーネント
 */
const StoryActionComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  if (!storyContainer.isVideoEnd) {
    return null;
  }
  switch (storyContainer.currentChapter?.actionType) {
    case ChapterActionType.SELECT:
      return (
        <StorySelectComponent
          selectList={storyContainer.currentChapter?.select}
        />
      );
    case ChapterActionType.KEYWORD:
      return (
        <StoryKeywordComponent
          keywordList={storyContainer.currentChapter?.keyword}
        />
      );
    case ChapterActionType.END:
      storyContainer.saveUserPlayAction(
        {
          chapterId: storyContainer.currentChapter.chapterId,
          type: ChapterActionType.END,
          action: "",
          nextChapterId: "",
        },
        0
      );
      return <StoryResultComponent />;
    default:
      return null;
  }
};

const StoryEndDialog: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  const onClickYes = () => {
    if (!storyContainer.currentChapter) {
      return;
    }

    if (storyContainer.currentChapter.actionType === ChapterActionType.END) {
      storyContainer.resetPlayStatus();
    }
    storyContainer.storyReset();
  };
  const onClickNo = () => {
    storyContainer.setIsEndDialog(false);
  };
  return (
    <YesNoAlertComponent
      title="確認"
      message="終了しますか？"
      yesCallback={onClickYes}
      noCallback={onClickNo}
      isOpen={storyContainer.isEndDialog}
    />
  );
};

/*
 * 説明表示コンポーネント
 */
const StoryExplanationTextComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  if (!storyContainer.isVideoEnd) {
    return null;
  }
  return (
    <div>
      {!storyContainer.isFullScreen && (
        <div className="explanation is-size-6 has-text-white has-text-centered has-text-weight-bold">
          {storyContainer.currentChapter?.explanation}
        </div>
      )}
      {storyContainer.currentChapter?.actionType !== ChapterActionType.END && (
        <div className="tap-text has-text-white">
          ボタン以外タップで非表示切替
        </div>
      )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import styles from "./story-free-play.module.scss";
import { StoryContainer } from "../../story.container";
import { FSWriteEventRepository } from "@6cuts/public-repository/write.event";

/*
 * ヘッダーコンポーネント
 */
export const StoryFreePlayComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();

  const onClickFreePlayHandler = async () => {
    storyContainer.setIsShowFreePlayModal(false);
    if (storyContainer.nextChapterActionInfo) {
      storyContainer.saveUserPlayAction(
        storyContainer.nextChapterActionInfo.action,
        storyContainer.nextChapterActionInfo.point
      );
      storyContainer.addScorePoint(storyContainer.nextChapterActionInfo.point);
      storyContainer.moveToNextChapter(
        storyContainer.nextChapterActionInfo.nextChapterId
      );
      storyContainer.playScorePoint(storyContainer.nextChapterActionInfo.point);
    }
  };

  const onClickCloseButton = () => {
    storyContainer.setIsEndDialog(true);
  };

  return (
    <div className={styles.component}>
      <div className="close has-text-right" onClick={onClickCloseButton}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/close.png`} />
      </div>
      <p className="text has-text-white">決済して引き続きお楽しみください</p>
      <div className="center">
        <p className="campaign has-text-white">無料キャンペーン中</p>
        <p className="limit has-text-white">
          {storyContainer.story!.price}円 → 0円
        </p>
      </div>

      <button className="button" onClick={onClickFreePlayHandler}>
        無料でPlay
      </button>
    </div>
  );
};

import * as firebase from "firebase";
import { docData } from "rxfire/firestore";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ReadPublishedReviewEntity } from "@6cuts/@dto/read.published_review";

const isReviewEntity = (arg: any) => {
  return arg.id !== undefined;
};

export class FSReadPublishedReviewRepository {
  static readonly COLLECTION_NAME: string = "read.published_review";

  static watchReview(data: {
    storyId: string;
  }): Observable<ReadPublishedReviewEntity | null> {
    const docRef = firebase
      .firestore()
      .collection(this.COLLECTION_NAME)
      .doc(data.storyId) as firebase.firestore.DocumentReference<
        ReadPublishedReviewEntity
      >;

    return docData<ReadPublishedReviewEntity>(docRef).pipe(
      map((entity: ReadPublishedReviewEntity) => {
        if (!isReviewEntity(entity)) {
          return null;
        }

        const review: ReadPublishedReviewEntity = {
          id: entity.id,
          number_of_reviews: entity.number_of_reviews,
          total_of_stars: entity.total_of_stars,
          created: entity.created,
          updated: entity.updated,
        };
        return review;
      })
    );
  }
}

import * as firebase from "firebase"
import { docData, collectionData } from 'rxfire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserMediaEntity } from '@6cuts/@dto/write.user';
import { ReadOwnUserPlayStatusEntity } from "@6cuts/@dto/read.own-user";



const isUserMediaEntity = (arg: any) => {
    return arg.id !== undefined
}

export class FSReadOwnUserPlayStatusRepository {

    static collectionName(data: { userId: string }): string {
        return `read.own_user/${data.userId}/play_status`
    }

    /**
     * 指定ストーリーの最新プレイ状況を取得
     * @param data 
     */
    static watch(
        data: {
            userId: string,
            storyId: string,
        }
    ): Observable<ReadOwnUserPlayStatusEntity | null> {
        const query = firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))
            .where("storyId", "==", data.storyId)
            .where("ended", "==", false)
            .orderBy("created", "desc")
            .limit(1) as firebase.firestore.Query<ReadOwnUserPlayStatusEntity>

        return collectionData<ReadOwnUserPlayStatusEntity>(query)
            .pipe(
                map((entityList: ReadOwnUserPlayStatusEntity[]) => {
                    if (entityList.length > 0) {
                        return entityList[0]
                    } else {
                        return null
                    }
                })
            )
    }

    /**
     * プレイ状況の一覧を監視
     * @param data 
     */
    static watchList(
        data: {
            userId: string,
        }
    ): Observable<ReadOwnUserPlayStatusEntity[]> {
        const query = firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))
            .orderBy("created", "desc")

        return collectionData<ReadOwnUserPlayStatusEntity>(query)
            .pipe(
                map((entityList: ReadOwnUserPlayStatusEntity[]) => {
                    if (entityList.length > 0) {
                        return entityList
                    } else {
                        return []
                    }
                })
            )
    }

    /**
     * プレイ状況の一覧を取得
     * @param data 
     */
    static getList(
        data: {
            userId: string,
        }
    ): Promise<ReadOwnUserPlayStatusEntity[]> {
        return this.watchList({ userId: data.userId })
            .pipe(take(1))
            .toPromise()
    }
}
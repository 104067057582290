import * as firebase from "firebase";

export class FSGetStorage {
  static getStoryImageUrl(storyId: string, imageId: string): Promise<string> {
    const path: string = `/stories/${storyId}/${imageId}`;
    const ref = firebase.app().storage().ref().child(path);
    return ref.getDownloadURL().then((url: string) => {
      return url;
    });
  }
  static getPublicStoryImageUrl(storyId: string, imageId: string): string {
    const path: string = `/stories/${storyId}/${imageId}`;
    return "https://storage.googleapis.com/6cuts-dev-public" + path;
  }
  static getStoryVideoUrl(storyId: string, videoId: string): Promise<string> {
    const path: string = `stories/${storyId}/${videoId}.mp4`;
    const ref = firebase.storage().ref().child(path);
    return ref.getDownloadURL().then((url: string) => {
      return url;
    });
  }
}

import React, { useState, useEffect } from "react";
import styles from "./story-login-modal.module.scss";
import { StoryContainer } from "../../story.container";
import { FSWriteEventRepository } from "@6cuts/public-repository/write.event";
import { useHistory } from "react-router";

/*
 * ヘッダーコンポーネント
 */
export const StoryLoginModalComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  const history = useHistory();

  const onClickGuesstPlayHandler = async () => {
    storyContainer.setIsShowLoginModal(false);
    //課金を表示
    storyContainer.setIsShowPurchaseModal(true);
  };

  const onClickSingUpHandler = async () => {
    history.push("/sign-up");
  };

  const onClickCloseButton = () => {
    storyContainer.setIsEndDialog(true);
  };

  return (
    <div className={styles.component}>
      <div className="close has-text-right" onClick={onClickCloseButton}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/close.png`} />
      </div>
      <p className="text has-text-white">決済して引き続きお楽しみください</p>
      <div className="center">
        <span>
          <label className="has-text-white is-size-2">
            ¥{storyContainer.story!.price}
          </label>
          <label className="has-text-white is-size-4">/PLAY</label>
        </span>
      </div>

      <div className="button-wapper">
        <button
          className="guest-start button has-text-white"
          onClick={onClickGuesstPlayHandler}
        >
          ゲストアカウントのまま続ける
        </button>
        <button
          className="regist button is-text is-small sign-up has-text-white"
          onClick={onClickSingUpHandler}
        >
          本登録してプレイデータを保存する
        </button>
      </div>
    </div>
  );
};

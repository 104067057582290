import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import *  as df from "date-fns"

import styles from "./play-status-list-cell.module.scss";


import { CSStoryImageRepository } from "@6cuts/storage/story/image";
import { ReadOwnUserPlayStatusEntity } from "@6cuts/@dto/read.own-user";
import { FSReadPublishedStoryRepository } from "@6cuts/public-repository/read.published_story";
import { ReadPublishedStoryEntity } from "@6cuts/@dto/read.published_story";
import { ReadPublicUserEntity } from "@6cuts/@dto/read.public-user";
import { FSReadPublicUserRepository } from "@6cuts/public-repository/read.public_user";


interface AccountPlayHistoryListCellComponentProps {
  playStatus: ReadOwnUserPlayStatusEntity
}

/*
 * オススメストーリーのカードコンポーネント
 */
export const AccountPlayHistoryListCellComponent: React.FC<AccountPlayHistoryListCellComponentProps> = (props: AccountPlayHistoryListCellComponentProps) => {
  const history = useHistory()

  const [story, setStory] = useState<ReadPublishedStoryEntity | null>(null)
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string | null>(null)
  const [storyOwner, setStoryOwner] = useState<ReadPublicUserEntity | null>(null)

  useEffect(() => {
    FSReadPublishedStoryRepository.get({ storyId: props.playStatus.storyId })
      .then((entity: ReadPublishedStoryEntity | null) => {
        if (entity === null) {
          return
        }
        setStory(entity)

        // サムネ画像URLの取得
        const url: string = CSStoryImageRepository.getPublicStoryImageUrl({
          storyId: entity.id,
          imageId: entity.thumbnailImage
        })
        setThumbnailImageUrl(url)

        // ストーリーのオーナー情報の取得
        FSReadPublicUserRepository.get({ userId: entity.ownerId })
          .then((ownerEntity: ReadPublicUserEntity | null) => {
            setStoryOwner(ownerEntity)
          })
      })
  }, [props.playStatus.storyId])

  /**
   * タイトルを改行コードで改行するための処理
   * @param text
   */
  const lineBreakedStoryTitle = (text: string | undefined) => {
    if (text === undefined) {
      return ""
    }

    const regex = /(\n)/g

    return text.split(regex).map(function (line: string, index: number) {
      return <p key={index} className="title is-4 has-text-weight-bold">{line}</p>
    })
  }

  /**
   * 最終プレイ時間
   */
  const playTime = () => {
    return df.format(props.playStatus.updated.toDate(), "yyyy-MM-dd HH:mm:ss")
  }

  /**
   * カードをクリックしたときの処理
   * 該当ストーリーに遷移する
   */
  const onClickCardHandler = () => {
    history.push(`/story/${props.playStatus.storyId}`)
  }

  if (story === null || storyOwner === null) {
    return null
  }

  return (
    <div className={styles.component} onClick={onClickCardHandler}>
      <div className="card">
        <div className="card-content">
          <div className="media">
            {
              thumbnailImageUrl !== null && (
                <div className="media-left">
                  <figure className="image is-128x128 is-square">
                    <img src={thumbnailImageUrl} alt="Placeholder image" />
                  </figure>
                </div>
              )
            }
            <div className="media-content">
              <div className="story-title">{lineBreakedStoryTitle(story!.title)}</div>
              <div className="subtitle is-6">{storyOwner !== null ? `@${storyOwner.nickname}` : ""}</div>
              <i className="fas fa-external-link-alt"></i>
              <div className="play-time subtitle is-6">{playTime()}</div>
              {
                (
                  (story!.scoreVisible === true && story!.scoreEnable === true) ||
                  (story!.scoreVisible === false && story!.scoreEnable === true && props.playStatus.ended === true)
                ) && (
                  <div className="score">
                    <span className="score-point title is-3">{props.playStatus.score}</span>
                    <span className="score-unit title is-5">{story.scoreUnit}</span>
                  </div>
                )
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react"
import styles from "./page.module.scss"
import { AppContainer } from "containers/app/app.container"
import { useHistory } from "react-router"
import { HeaderComponent } from "components/common/header/component"
import { FSReadOwnUserPlayStatusRepository } from "@6cuts/public-repository/read.own_user.play_status"
import { ReadOwnUserPlayStatusEntity } from "@6cuts/@dto/read.own-user"
import { AccountPlayHistoryListCellComponent } from "./play-status-list-cell"


/*
 * アカウント管理画面トップ
 */
export const AccountPlayHistoryPageComponent: React.FC = () => {
    const appContainer = AppContainer.useContainer()
    const history = useHistory()

    const [playStatusList, setPlayStatusList] = useState<ReadOwnUserPlayStatusEntity[]>([])

    useEffect(() => {
        if (appContainer.userId === null) {
            return
        }

        FSReadOwnUserPlayStatusRepository.getList({ userId: appContainer.userId })
            .then((entityList: ReadOwnUserPlayStatusEntity[]) => {
                setPlayStatusList(entityList)
            })
    }, [appContainer.userId])

    return (
        <div className={styles.page}>
            <HeaderComponent
                backEnabled={true}
                backTo="/account"
                title="プレイ履歴"
            ></HeaderComponent>

            {
                playStatusList.map((playStatus: ReadOwnUserPlayStatusEntity) => {
                    return (
                        <AccountPlayHistoryListCellComponent
                            playStatus={playStatus}
                        ></AccountPlayHistoryListCellComponent>
                    )
                })
            }

        </div>
    )
}

import React, { useEffect, useState } from "react";
import { StoryContainer } from "../story.container";
import styles from "./story-top-component.module.scss";
import { StarComponent } from "../../common/star/star-component";
import {
  YesNoAlertComponent,
  OkAlertComponent,
} from "components/common/alert/alert";
import { FSGetPlayCountRepository } from "@6cuts/public-repository/read.play_count";
import { ReadPlayCountEntity } from "@6cuts/@dto/read.play_count";
import { AppContainer } from "containers/app/app.container";
import { useHistory, useParams } from "react-router-dom";
import { CSUserImageRepository } from "@6cuts/storage/users/image";
import { AmazonPayCompleteComponent } from "../../common/purchase/amazon_pay/payment-complete/component";
import { FSReadOwnUserPurchaseRepository } from "@6cuts/public-repository/read.own_user.purchase";
import { ReadOwnUserPruchaseEntity } from "@6cuts/@dto/read.own-user";
import { UserPurchaseState } from "@6cuts/@dto/write.user";
import { FSWriteUserPlayStatusRepository } from "@6cuts/public-repository/write.user.play_status";

interface StoryTopComponentProps {
  canStart: boolean;
}

/*
 * ストーリートップ
 */
export const StoryTopComponent: React.FC<StoryTopComponentProps> = (
  props: StoryTopComponentProps
) => {
  const { storyId } = useParams();
  const storyContainer = StoryContainer.useContainer();
  const appContainer = AppContainer.useContainer();
  const history = useHistory();
  // 無料プレイ回数
  const [canFreePlay, setCanFreePlay] = useState<boolean>(false);
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [userImageUrl, setUserImageUrl] = useState<string | null>(null);
  const [isAmazonPayPurchased, setIsAmazonPayPurchased] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (appContainer.userId === null) {
      return;
    }
    CSUserImageRepository.getUserThumbnailImageUrl({
      userId: appContainer.userId,
    }).then((url: string) => {
      const now = new Date().getTime();
      setUserImageUrl(`${url}&time=${now}`);
    });
  }, [appContainer.user?.updated]);

  /** 続きから始めるかどうかの確認ダイアログの表示フラグ */
  const [
    isShowConfirmContinueDialog,
    setIsShowConfirmContinueDialog,
  ] = useState<boolean>(false);
  const continueMessage: string = `
  途中までプレイしたデータが残っています。
  続きから始めますか？
  `;

  const [
    isShowDiscardPlaydataDialog,
    setIsShowDiscardPlaydataDialog,
  ] = useState<boolean>(false);
  const discardMessage: string = `
  プレイ中のデータは破棄されます。
  （購入済みの場合、ご注意ください）
  `;

  useEffect(() => {
    // ブラウザに表示するタイトルの設定
    if (storyContainer.story) {
      document.title = `6cuts | ${storyContainer.story.title}`;
      //フリープレイ回数の取得
      FSGetPlayCountRepository.watchPlayCount(
        storyContainer.story.id
      ).subscribe((playCount: ReadPlayCountEntity | null) => {
        let freeplayCount = 0;
        if (playCount !== null) {
          freeplayCount = playCount.free_play;
        }
        setCanFreePlay(freeplayCount < storyContainer.story!.numberOfFreePlays);
      });
    } else {
      document.title = `6cuts`;
    }
  }, [storyContainer.story]);

  //課金して戻ってきたかのチェック
  useEffect(() => {
    if (storyContainer.userPlayStatus !== null) {
      if (
        storyContainer.userPlayStatus.purchaseId !== "" &&
        !storyContainer.userPlayStatus.purchased
      ) {
        //amazon pay購入が完了しているかのチェック
        FSReadOwnUserPurchaseRepository.get({
          userId: appContainer.userId!,
          transactionId: storyContainer.userPlayStatus.purchaseId,
        }).then((entity: ReadOwnUserPruchaseEntity | null) => {
          //課金が完了しているなら課金状態に
          if (entity?.status === UserPurchaseState.complete) {
            //アマゾンで決済が購入完了表示を出すフラグを立てる
            setIsAmazonPayPurchased(true);
            //課金済みに設定してあげる
            FSWriteUserPlayStatusRepository.updatePurchaseStatus({
              playId: storyContainer.userPlayStatus!.id,
              userId: appContainer.userId!,
              purchased: true,
            });
            //課金数をインクリメント
            storyContainer.IncrementPurchase();
            //課金済みにセット
            storyContainer.setIsPurchased(true);
          }
        });
      }
    }
  }, [storyContainer.userPlayStatus]);

  /**
   * プレイボタンを押したときの動作
   * プレイ中のデータがあれば確認ダイアログを表示する
   * ＜初回の動画再生のため非同期禁止＞
   */
  const onClickStart = () => {
    if (storyContainer.userPlayStatus === null) {
      //フリープレイ可能かどうか判断する
      if (canFreePlay) {
        storyContainer.setIsFreePlay(true);
      }
      storyContainer.setIsStart(true);
    } else {
      setIsShowConfirmContinueDialog(true);
    }
  };

  const onClickUserHandler = () => {
    if (appContainer.isAnonymous) {
      history.push("/sign-up");
    } else {
      history.push("/account");
    }
  };

  /**
   * タイトルを改行コードで改行するための処理
   * @param text
   */
  const storyTitle = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line: string, index: number) {
      return <p className="title is-3 has-text-white">{line}</p>;
    });
  };

  /**
   * レビュー平均値の算出
   */
  const averageStar = () => {
    if (!storyContainer.review) {
      return 0;
    }
    const averageStar: number =
      storyContainer.review.total_of_stars /
      storyContainer.review.number_of_reviews;
    return averageStar;
  };

  // ----------------------------------------------------
  //
  //  Continue Dialog Callback
  //
  // ----------------------------------------------------
  /**
   * 続きから始めるかの確認ダイアログで、続きから始めるを選択した場合
   * そのまま続きから始める
   * ＜初回の動画再生のため非同期禁止＞
   */
  const continueDialogOnClickYesHandler = () => {
    storyContainer.initUserPlayStatus();
    storyContainer.setIsStart(true);
  };

  /**
   *　破棄確認ダイアログを表示させる
   */
  const continueDialogOnClickNoHandler = () => {
    setIsShowDiscardPlaydataDialog(true);
    setIsShowConfirmContinueDialog(false);
  };

  /**
   * 破棄するを選択した場合
   * 現在のプレイ状況をリセットして終了させ、初めからスタートする
   * ＜初回の動画再生のため非同期禁止＞
   */
  const discardDialogOnClickYesHandler = () => {
    storyContainer.resetPlayStatus();
    storyContainer.storyReset();
    //フリープレイ可能かどうか判断する
    if (canFreePlay) {
      storyContainer.setIsFreePlay(true);
    }
    storyContainer.setIsStart(true);
  };

  /**
   * 破棄するをキャンセルした場合、TOPに戻る
   */
  const discardDialogOnClickNoHandler = () => {
    setIsShowDiscardPlaydataDialog(false);
  };

  const deleteNewLine = (myLen: string) => {
    return myLen.replace(/\r?\n/g, "");
  };

  const onClickShareHandler = () => {
    if (storyContainer.story === undefined) {
      return;
    }
    const navi: any = window.navigator;
    if (navi.share !== undefined) {
      const title: string = deleteNewLine(storyContainer.story.title);
      const url: string = `${window.location.origin}/story/${storyContainer.story.id}&openExternalBrowser=1`;

      let shareText: string = "";
      shareText = `【${title}】で遊ぼう！`;

      navi.share({
        title: title,
        text: shareText,
        url: url,
      });
    } else {
      alert(`web share api is not supported in this browser.`);
    }
  };

  if (isAmazonPayPurchased) {
    return (
      <AmazonPayCompleteComponent
        onClickResumptionHandler={continueDialogOnClickYesHandler}
      />
    );
  }

  return (
    <div className={styles.container}>
      <img
        className="background"
        src={storyContainer.titleImageUrl}
        alt=""
      ></img>
      <div className="background-mask"></div>
      <div className="black-mask"></div>
      <div></div>

      {appContainer.isAnonymous && (
        <button
          className="button is-text is-small has-text-white userName"
          onClick={onClickUserHandler}
        >
          ゲストアカウント
        </button>
      )}

      <div className="share">
        <button
          className="button is-text is-small has-text-white"
          onClick={onClickShareHandler}
        >
          シェアする
        </button>
      </div>

      {!appContainer.isAnonymous && (
        <figure className="account image is-48x48" onClick={onClickUserHandler}>
          <img
            className="is-rounded"
            src={
              userImageUrl !== null
                ? userImageUrl
                : `${process.env.PUBLIC_URL}/assets/images/user.png`
            }
            alt=""
          />
        </figure>
      )}
      <div className="title-wrapper">
        <StarComponent
          star={averageStar()}
          numberOfReviews={
            storyContainer.review ? storyContainer.review.number_of_reviews : 0
          }
        />
        {storyContainer.story?.titleVisible && (
          <div className="story-title">
            {storyTitle(storyContainer.story?.title)}
          </div>
        )}
      </div>
      {storyContainer.story !== undefined && props.canStart && (
        <div className="story-top-footer has-text-white">
          <div className="text-caution">※動画作品の場合、音声が流れます※</div>
          <button className="trial button is-light" onClick={onClickStart}>
            {storyContainer.userPlayStatus !== null
              ? "プレイ中"
              : storyContainer.story?.price === 0
              ? "スタート"
              : "お試しスタート"}
          </button>

          <div className="purchase has-text-white is-size-6">
            {storyContainer.story?.price === 0 && "全編無料"}
            {storyContainer.story?.price !== 0 &&
              !canFreePlay &&
              "全編プレイ" + storyContainer.story?.price + "円"}
            {canFreePlay &&
              `先着${storyContainer.story!.numberOfFreePlays}人まで無料`}
          </div>
        </div>
      )}
      <YesNoAlertComponent
        title="確認"
        message={continueMessage}
        yesText="続きから始める"
        noText="最初から始める"
        yesCallback={continueDialogOnClickYesHandler}
        noCallback={continueDialogOnClickNoHandler}
        isOpen={isShowConfirmContinueDialog}
      />
      <YesNoAlertComponent
        title="確認"
        message={discardMessage}
        yesText="破棄する"
        noText="キャンセル"
        yesCallback={discardDialogOnClickYesHandler}
        noCallback={discardDialogOnClickNoHandler}
        isOpen={isShowDiscardPlaydataDialog}
      />
      <OkAlertComponent
        title="エラー"
        message="無料プレイ枠の上限を超えました"
        okCallback={() => {
          setIsErrorAlert(false);
        }}
        isOpen={isErrorAlert}
      />
    </div>
  );
};

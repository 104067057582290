import React, { useState, useRef, useEffect } from "react";
import "./story-edit-page.component.scss";
import { useParams } from "react-router-dom";

export const StoryEditPageComponent: React.FC = () => {
    const { pageId, xxxId } = useParams();

    return (
        <div>
            <div className="title">Story Editor {pageId} {xxxId}</div>
        </div>
    )
}
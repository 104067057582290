import React, { useState, useEffect, useRef } from "react";
import styles from "./page.module.scss";
import { AppContainer } from "containers/app/app.container";
import { useHistory } from "react-router";
import { FSWriteContactRepository } from "@6cuts/public-repository/write.contact";
import { HeaderComponent } from "components/common/header/component";
import { ContactType } from "@6cuts/@dto/wirte.contact";
import firebase from "firebase";

interface Contact {
  userId: string;
  name: string;
  email: string;
  contactType: ContactType;
  details: string;
}

/*
 * アカウント登録
 */
export const ContactPageComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const history = useHistory();

  const contact: Contact = {
    userId: appContainer.userId!,
    name: "",
    email: "",
    contactType: ContactType.Request,
    details: "",
  };

  // 名前
  const [name, setName] = useState<string>("");

  // メールアドレス
  const [email, setEmail] = useState<string>("");

  // 問合せの種類
  const [contactType, setContactType] = useState<ContactType>(
    ContactType.Request
  );

  // 詳細
  const [details, setDetails] = useState<string>("");

  // ローディング
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>("");

  //送信フラグ
  const [isSended, setIsSended] = useState<Boolean>(false);

  const [isEmail, setIsEmail] = useState<Boolean>(true);

  const [sendDateStr, setSendDateStr] = useState<string>("");

  const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;

  useEffect(() => {}, []);

  /**
   * ニックネームが変更された時の処理
   * @param event
   */
  const onChangeNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  /**
   * メールアドレスが変更された時の処理
   * @param event
   */

  const onChangeEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmail(regexp.test(event.target.value));
    setEmail(event.target.value);
  };

  /**
   * 詳細が変更された時の処理
   * @param event
   */
  const onChangeTextAreaHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDetails(event.target.value);
  };

  /**
   * 要望が変更された時の処理
   * @param event
   */
  const onChangeContactTypeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setContactType(event.target.value as ContactType);
  };

  /**
   * 送信ボタン押下時の処理
   */
  const onClickSendHandler = async () => {
    setIsSaving(true);

    const now = firebase.firestore.Timestamp.now().toDate();
    setSendDateStr(dateToStr(now));

    FSWriteContactRepository.create({
      userId: appContainer.userId!,
      name: name,
      email: email,
      details: details,
      contactType: contactType,
    }).then((_) => {
      setIsSaving(false);
      setIsSended(true);
    });
  };

  const getContactTypeText = (type: ContactType) => {
    switch (type) {
      case ContactType.Request:
        return "要望";
      case ContactType.Trouble:
        return "不具合";
      case ContactType.Parchase:
        return "決済について";
      case ContactType.Other:
        return "その他";
    }
  };

  const dateToStr = (date: Date) => {
    let format = "YYYY年MM月DD日 hh:mm";
    format = format.replace(/YYYY/g, date.getFullYear().toString());
    format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ("0" + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ("0" + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
    return format;
  };

  /**
   * 送信ボタン押せるかどうか
   */
  const sendButtonEnabled: boolean =
    name !== "" && email !== "" && isEmail && details !== "";

  return (
    <div className={styles.page}>
      <HeaderComponent
        backEnabled={true}
        title={"お問い合わせ"}
      ></HeaderComponent>
      {!isSended && (
        <section className="section">
          <div className="container">
            <div className="top-text">
              <p>6cutsをご利用いただきありがとうございます。</p>
              <p>以下のフォームよりお問い合わせいただけます。</p>
            </div>
            <div className="field is-vertical">
              <div className="field-label is-normal">
                <label className="label">お名前（必須）</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input
                      className="input"
                      type="text"
                      // maxLength={18}
                      // placeholder="最大18文字"
                      onChange={onChangeNameHandler}
                      value={name}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-vertical">
              <div className="field-label is-normal">
                <label className="label">メールアドレス（必須）</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input
                      className={["input", isEmail ? "" : "is-danger"].join(
                        " "
                      )}
                      type="email"
                      onChange={onChangeEmailHandler}
                      value={email}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-vertical">
              <div className="field-label is-normal">
                <label className="label">内容（選択してください）</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <div className="select">
                      <select onChange={(e) => onChangeContactTypeHandler(e)}>
                        <option value="request">要望</option>
                        <option value="trouble">不具合</option>
                        <option value="parchase">決済について</option>
                        <option value="other">その他</option>
                      </select>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-vertical">
              <div className="field-label is-normal">
                <label className="label">お問い合わせ詳細</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <textarea
                      className="textarea"
                      onChange={onChangeTextAreaHandler}
                      value={details}
                    ></textarea>
                  </p>
                </div>
              </div>
            </div>

            <div className="error-message has-text-danger">{errorMessage}</div>

            <div className="action">
              <div>
                <button
                  className="button is-outlined sign-in"
                  disabled={!sendButtonEnabled}
                  onClick={onClickSendHandler}
                >
                  送信
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      {isSended && (
        <section className="section">
          <div className="container">
            <div className="top-text">お問い合わせを送信しました。</div>
            <div className="card">
              <div className="card-content">
                <div className="field is-vertical">
                  <div className="field-label is-normal">
                    <label className="label">お名前</label>
                  </div>
                  <div className="field-body">{name}</div>
                </div>
                <div className="field is-vertical">
                  <div className="field-label is-normal">
                    <label className="label">メールアドレス</label>
                  </div>
                  <div className="field-body">{email}</div>
                </div>
                <div className="field is-vertical">
                  <div className="field-label is-normal">
                    <label className="label">内容</label>
                  </div>
                  <div className="field-body">
                    {getContactTypeText(contactType)}
                  </div>
                </div>
                <div className="field is-vertical">
                  <div className="field-label is-normal">
                    <label className="label">お問い合わせ詳細</label>
                  </div>
                  <div className="field-body">{details}</div>
                </div>
                <div className="field is-vertical">
                  <div className="field-label is-normal">
                    <label className="label">送信日時</label>
                  </div>
                  <div className="field-body">{sendDateStr}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {isSaving && (
        <div className="saving-mask">
          <button className="loading button is-loading is-text"></button>
        </div>
      )}
    </div>
  );
};

import React, { useState } from "react";
import "./story-keyword.component.scss";
import { StoryContainer } from "../../story.container";
import { ChapterKeyWord, ChapterActionType } from "@6cuts/@dto/write.story";
import { UserPlayAction } from "@6cuts/@dto/write.user";
import { AppContainer } from "containers/app/app.container";

interface StoryKeywordProps {
  keywordList: ChapterKeyWord;
}

export const StoryKeywordComponent: React.FC<StoryKeywordProps> = (
  props: StoryKeywordProps
) => {
  const storyContainer = StoryContainer.useContainer();
  const appContainer = AppContainer.useContainer();

  //Hooksを使ってinputをハンドリング
  const [keyword, setKeyword] = useState("");
  const [keywordValid, setKeywordValid] = useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    setKeywordValid(checkZenKatakana(event.target.value));
  };

  const onClickScreen = () => {
    storyContainer.switchFullScreen();
  };

  const onClickInput = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  const onClicksDecisionButton = async () => {
    console.log(props.keywordList);
    if (!storyContainer.currentChapter) {
      return;
    }

    //TO DO なにか入力してくださいとか出す
    if (keyword === "") {
      return;
    }

    let isCorrect = false;
    let point: number = 0;
    let nextChapterId: string = "";
    //正解
    props.keywordList.answer.forEach((data: { [index: string]: any }) => {
      if (data.text === keyword) {
        isCorrect = true;
        point = data.point;
        nextChapterId = data.transitionId;
      }
    });

    // 不正解の場合は不正解用のポイントとチャプターをセット
    if (!isCorrect) {
      point = props.keywordList.incorrect.point;
      nextChapterId = props.keywordList.incorrect.transitionId;
    }

    // 正解の場合
    const action: UserPlayAction = {
      chapterId: storyContainer.currentChapter.chapterId,
      type: ChapterActionType.KEYWORD,
      action: keyword,
      nextChapterId: nextChapterId,
    };

    if (storyContainer.isMoveNextStoryEnabled()) {
      // 課金不要
      storyContainer.saveUserPlayAction(action, point);
      storyContainer.moveToNextChapter(nextChapterId);
      storyContainer.addScorePoint(point);
      storyContainer.playScorePoint(point);
    } else {
      //アクションを一時保存
      storyContainer.setNextChapterActionInfo({
        nextChapterId: nextChapterId,
        point: point,
        action: action,
      });
      if (storyContainer.isFreePlay) {
        storyContainer.setIsShowFreePlayModal(true);
      } else {
        if (appContainer.isAnonymous) {
          storyContainer.setIsShowLoginModal(true);
        } else {
          // 課金画面表示
          storyContainer.setIsShowPurchaseModal(true);
        }
      }
    }
  };

  const checkZenKatakana = (str: string) => {
    str = str == null ? "" : str;
    if (str.match(/^[ァ-ヶー　]+$/)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="keyword-wrapper">
      <div className="fullscreen-panel" onClick={onClickScreen}></div>
      {!storyContainer.isFullScreen && (
        <div className="keyword">
          <div className="input-wrapper">
            <input
              name="title"
              className={[
                "input-keyword",
                "input",
                keywordValid ? "" : "is-danger",
              ].join(" ")}
              type="text"
              placeholder="カタカナで入力"
              onChange={handleChange}
              onClick={onClickInput}
            />
          </div>

          <div className="decision">
            <div className="error">
              {keywordValid ? "" : "すべて全角カタカナで入力してください。"}
            </div>
            <div className="button-wrapper">
              <button
                className="button-decision button is-light"
                disabled={keyword === "" || !keywordValid}
                onClick={onClicksDecisionButton}
              >
                確定
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

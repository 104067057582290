import * as firebase from "firebase";

import { v4 as uuidv4 } from "uuid";
import {
  WriteUserPlayStatusEntity,
  UserPlayAction,
} from "@6cuts/@dto/write.user";

export class FSWriteUserPlayStatusRepository {
  static collectionName(data: { userId: string }): string {
    return `write.user/${data.userId}/play_status`;
  }

  static create(data: {
    userId: string;
    storyId: string;
    score: number;
    actionList: UserPlayAction[];
    purchased: boolean;
    isFreePlay: boolean;
    purchaseId: string;
    ended: boolean;
  }): Promise<string> {
    const docId: string = uuidv4();
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

    const entity: WriteUserPlayStatusEntity = {
      id: docId,
      userId: data.userId,
      storyId: data.storyId,
      score: data.score,
      actionList: data.actionList,
      purchased: data.purchased,
      purchaseId: data.purchaseId,
      isFreePlay: data.isFreePlay,
      ended: data.ended,
      created: now,
      updated: now,
    };

    return firebase
      .firestore()
      .collection(this.collectionName({ userId: data.userId }))
      .doc(docId)
      .set(entity)
      .then(() => {
        return docId;
      });
  }

  static updatePlayAction(data: {
    playId: string;
    userId: string;
    storyId: string;
    score: number;
    actionList: UserPlayAction[];
    ended: boolean;
  }): Promise<void> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

    return firebase
      .firestore()
      .collection(this.collectionName({ userId: data.userId }))
      .doc(data.playId)
      .update({
        score: data.score,
        actionList: data.actionList,
        ended: data.ended,
        updated: now,
      })
      .then(() => {
        return;
      });
  }

  static updatePurchaseId(data: {
    playId: string;
    userId: string;
    purchaseId: string;
  }): Promise<void> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

    return firebase
      .firestore()
      .collection(this.collectionName({ userId: data.userId }))
      .doc(data.playId)
      .update({
        purchaseId: data.purchaseId,
        updated: now,
      })
      .then(() => {
        return;
      });
  }

  static updatePurchaseStatus(data: {
    playId: string;
    userId: string;
    purchased: boolean;
  }): Promise<void> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

    return firebase
      .firestore()
      .collection(this.collectionName({ userId: data.userId }))
      .doc(data.playId)
      .update({
        purchased: data.purchased,
        updated: now,
      })
      .then(() => {
        return;
      });
  }

  static updateFinishedStatus(data: {
    playId: string;
    userId: string;
    finished: boolean;
  }): Promise<void> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

    return firebase
      .firestore()
      .collection(this.collectionName({ userId: data.userId }))
      .doc(data.playId)
      .update({
        ended: data.finished,
        updated: now,
      })
      .then(() => {
        return;
      });
  }
}

import * as firebase from "firebase";

// import { ChapterActionType } from "./write.story"

export const Gender = {
  male: "male",
  female: "female",
  unknown: "unknown",
};

export interface ReadOwnUserEntity {
  id: string;
  nickname: string;
  isExistIcon: boolean;
  gender: string;
  birthday: string;
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
}

export interface WriteUserEntity {
  id: string;
  nickname: string;
  isExistIcon: boolean;
  gender: string;
  birthday: string;
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
}

export interface UserMediaEntity {
  id: string;
  userId: string;
  status: string; // prepare, complete, failed
  memo: string;
  color: string;
  duration: number;
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
}

export const UserPurchaseItemType = {
  story: "story",
};

export const UserPurchaseState = {
  select: "select", //課金選択時
  order: "order", // 注文データ作成
  approve: "approve", // 未支払
  complete: "complete", // 支払済
  cancel: "cancel", // キャンセル済
  refund: "refund", // 返金
  error: "error", //エラー
};

export const UserPurchasePaymentType = {
  paypal: "paypal",
  amazonPay: "amazonPay",
};

export interface WriteUserPurchaseEventEntity {
  userId: string;
  itemType: string; // UserPurchaseItemType
  itemId: string; // Story ID 等
  itemName: string; // 購入物の内容 (Story Title 等)
  transactionId: string;
  orderId: string; // 支払サービスのID (paypalから帰ってきたorderID) (取引IDとは別)
  paymentType: string; // 注文方法 (UserPurchasePaymentType)
  price: number; // 円
  status: string;
  data: { [key: string]: any };
  created: firebase.firestore.Timestamp;
}

/**
 * @param action
 *   ChapterActionType === "keyword" -> some input value (string)
 *   ChapterActionType === "select" -> selection index (number)
 *   ChapterActionType === "end" -> "" (string)
 */
export interface UserPlayAction {
  chapterId: string;
  type: string;
  action: string | number;
  nextChapterId: string;
}

export interface WriteUserPlayStatusEntity {
  id: string;
  userId: string;
  storyId: string;
  score: number;
  actionList: UserPlayAction[];
  purchased: boolean;
  purchaseId: string;
  isFreePlay: boolean;
  ended: boolean;
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
}
